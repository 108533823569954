import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import Client from '../utils/network';
import {
  COMMIT_REF_STORAGE_KEY,
  REFRESH_REQUIRED_STORAGE_KEY,
} from '../constants';

const REFRESH_INTERVAL = 60000;

const listenForNewBuild = () => {
  setInterval(async () => {
    const { commitRef } = await Client.get('/getBuildInfo');

    if (`${localStorage.getItem(COMMIT_REF_STORAGE_KEY)}` !== `${commitRef}`) {
      localStorage.setItem(COMMIT_REF_STORAGE_KEY, commitRef);
      localStorage.setItem(REFRESH_REQUIRED_STORAGE_KEY, 1);
    }
  }, REFRESH_INTERVAL);
};

const isRefreshRequired = () => {
  return !!localStorage.getItem(REFRESH_REQUIRED_STORAGE_KEY);
};

export const refresh = () => {
  localStorage.removeItem(REFRESH_REQUIRED_STORAGE_KEY);
  window.location.reload();
};

const RefreshWrapper = ({ children }) => {
  const history = useHistory();

  useEffect(() => {
    listenForNewBuild();

    history.listen(() => {
      if (isRefreshRequired()) refresh();
    });
  }, []);

  return <>{children}</>;
};

RefreshWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default RefreshWrapper;
