import { NavLink } from 'react-router-dom';
import { FiChevronRight } from 'react-icons/fi';
import WriteOffForm from './WriteOffForm';
import { H1 } from './elements/Elements';

const CreateWriteOff = () => {
  return (
    <div style={{ maxWidth: 'var(--narrow-max-width)' }}>
      <H1>
        <NavLink to="/write-offs">Write Offs</NavLink> <FiChevronRight /> Create
        Write Off
      </H1>
      <WriteOffForm />
    </div>
  );
};

export default CreateWriteOff;
