import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { useFormikContext } from 'formik';
import { FlexItem } from './elements/Elements';
import {
  getCents,
  humanReadableDollars,
  calculateSaleTotal,
} from '../utils/helpers';

const SubTotal = styled.div`
  font-size: 1.5rem;
  font-weight: 500;
  text-align: right;

  span {
    display: block;
    font-size: 0.9rem;
    font-weight: 500;
  }
`;

const SaleFormTotals = ({ paid }) => {
  const [total, setTotal] = useState();
  const [estimatedProfit, setEstimatedProfit] = useState();
  const { values } = useFormikContext();

  useEffect(() => {
    const calculatedTotal = calculateSaleTotal(values);

    const productsGrossProfit = values.products.reduce(
      (a, { cost, salesTax, price }) => a + price - cost - salesTax,
      0,
    );

    setTotal(calculatedTotal);
    setEstimatedProfit(productsGrossProfit - getCents(values.appliedCredit));
  }, [values]);

  return (
    <>
      <FlexItem marginAuto>
        <SubTotal
          style={{
            textAlign: paid && 'left',
          }}
        >
          <span>Sale Total</span>
          {humanReadableDollars(total / 100)}
        </SubTotal>
      </FlexItem>
      <FlexItem style={{ color: 'var(--gray)' }} marginAuto>
        <SubTotal>
          <span>Estimated Profit</span>
          {humanReadableDollars(estimatedProfit / 100)}
        </SubTotal>
      </FlexItem>
    </>
  );
};

SaleFormTotals.propTypes = {
  paid: PropTypes.bool,
};

SaleFormTotals.defaultProps = {
  paid: false,
};

export default SaleFormTotals;
