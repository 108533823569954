import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { humanReadableDollars } from '../utils/helpers';
import { Button, TableItem, TableRow } from './elements/Elements';

const Status = styled.span`
  display: inline-block;
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
  font-weight: 500;
  letter-spacing: 0.5px;
  border-radius: 3px;
`;

const SalesRow = ({ sale }) => {
  const [grossProfit, setGrossProfit] = useState();

  const {
    _id: id,
    paid,
    createdAt,
    customer,
    paymentMethod,
    productPricesSum,
    shippingPrice,
    appliedCredit,
    products,
  } = sale;

  useEffect(() => {
    const productsGrossProfit = products.reduce(
      (a, { cost, salesTax, price }) => a + price - cost - salesTax,
      0,
    );

    setGrossProfit(productsGrossProfit - appliedCredit);
  }, []);

  return (
    <>
      <TableRow key={id}>
        <TableItem>
          {new Intl.DateTimeFormat('en-US', {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric',
          }).format(new Date(createdAt))}
        </TableItem>
        <TableItem>
          <NavLink to={`/customers/${customer._id}`}>{customer.name}</NavLink>
        </TableItem>
        <TableItem>{paymentMethod}</TableItem>
        <TableItem>
          <Status
            style={{
              backgroundColor: paid
                ? 'var(--success--light)'
                : 'var(--error--light)',
              color: paid ? 'var(--success)' : 'var(--error)',
            }}
          >
            {paid ? 'Paid' : 'Unpaid'}
          </Status>
        </TableItem>
        <TableItem
          style={{
            textAlign: 'right',
          }}
        >
          {humanReadableDollars((productPricesSum + shippingPrice) / 100)}
        </TableItem>
        <TableItem
          style={{
            textAlign: 'right',
            color: grossProfit < 0 && 'var(--error)',
          }}
        >
          {humanReadableDollars(grossProfit / 100)}
        </TableItem>
        <TableItem style={{ textAlign: 'right' }}>
          <Button tiny to={`/sales/${id}`}>
            {sale.paid ? 'View' : 'Edit'}
          </Button>
        </TableItem>
      </TableRow>
    </>
  );
};

SalesRow.propTypes = {
  sale: PropTypes.object.isRequired,
};

export default SalesRow;
