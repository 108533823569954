import { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

const Wrapper = styled.div`
  position: absolute;
  background-color: var(--overlay);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
`;

const InnerWrapper = styled.div`
  position: absolute;
  background-color: rgba(var(--black), 0.5);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 2;

  @media (max-width: 600px) {
    justify-content: flex-end;
    padding: 0;
  }
`;

const Container = styled.div`
  position: relative;
  background-color: white;
  align-self: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  padding: var(--space);

  @media (max-width: 600px) {
    max-width: 600px;
    border-radius: 0;
  }
`;

const ModalControls = ({ children, close }) => {
  useEffect(() => {
    const closeOnEscape = (e) => {
      if (close && e.keyCode === 27) {
        close();
      }
    };

    window.addEventListener('keydown', closeOnEscape);

    return () => {
      window.removeEventListener('keydown', closeOnEscape);
    };
  }, []);

  return <>{children}</>;
};

ModalControls.propTypes = {
  children: PropTypes.node.isRequired,
  close: PropTypes.func.isRequired,
};

const ModalBase = ({ children, isOpen, close }) => {
  if (!isOpen) return null;

  return (
    <Wrapper>
      <InnerWrapper>
        <Container>
          <ModalControls close={close}>{children}</ModalControls>
        </Container>
      </InnerWrapper>
    </Wrapper>
  );
};

ModalBase.propTypes = {
  children: PropTypes.node.isRequired,
  close: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default ModalBase;
