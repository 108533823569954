import { useEffect, useState } from 'react';
import { NavLink, useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FiChevronRight } from 'react-icons/fi';
import Client from '../utils/network';
import H1 from './elements/H1';
import ExpenseForm from './ExpenseForm';
import { Button, LoadingIndicator, P } from './elements/Elements';
import ResourceSection from './ResourceSection';

const Expense = () => {
  const [data, setData] = useState();
  const [deleting, setDeleting] = useState();
  const { id } = useParams();
  const { push } = useHistory();

  useEffect(() => {
    (async () => {
      try {
        setData(await Client.get(`/getExpense?id=${id}`));
      } catch (error) {
        toast.error(error);
      }
    })();
  }, [id]);

  const deleteRecord = async () => {
    try {
      setDeleting(true);

      if (!window.confirm('Please confirm to delete this expense.')) return;

      await Client.delete(`deleteExpense?id=${id}`, { data: {} });

      toast.success('Expense successfully deleted.');
      setDeleting(false);
      push('/expenses');
    } catch (error) {
      setDeleting(false);
      toast.error(error);
    }
  };

  if (!data) return <LoadingIndicator />;

  return (
    <div style={{ maxWidth: 'var(--narrow-max-width)' }}>
      <H1>
        <NavLink to="/expenses">Expenses</NavLink> <FiChevronRight /> Edit
        Expense
      </H1>
      <ExpenseForm data={data} />
      {!data.generated && (
        <ResourceSection>
          <P style={{ marginBottom: '1rem', fontSize: '.9rem' }}>
            Deleting this expense will completely remove it from your reporting.
          </P>
          <Button
            style={{ backgroundColor: 'var(--error)' }}
            onClick={deleteRecord}
            small
            loading={deleting}
          >
            Delete Expense
          </Button>
        </ResourceSection>
      )}
    </div>
  );
};

export default Expense;
