import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

const StyledLoadingIndicator = styled.div`
  position: absolute;
  top: 53%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const LoadingIndicator = ({ small, ...props }) => {
  return (
    <StyledLoadingIndicator {...props}>
      <div
        style={{ transform: small ? 'scale(1, 1)' : 'scale(2, 2)' }}
        className="loader-inner ball-clip-rotate"
      >
        <div
          style={{
            border: '1px solid transparent',
            borderBottomColor: 'var(--gray)',
          }}
        />
      </div>
    </StyledLoadingIndicator>
  );
};

LoadingIndicator.propTypes = {
  small: PropTypes.bool,
};

LoadingIndicator.defaultProps = {
  small: false,
};

export default LoadingIndicator;
