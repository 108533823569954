import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

const StyledFlexItem = styled.div`
  flex: ${({ flex }) => flex || '1'};
  ${({ marginAuto }) => marginAuto && 'margin: auto 0;'}
  ${({ alignRight }) => alignRight && 'text-align: right;'}
  ${({ alignRight }) => alignRight && '* { margin-left: auto; }'}
`;

const FlexItem = ({ children, ...props }) => {
  return <StyledFlexItem {...props}>{children}</StyledFlexItem>;
};

FlexItem.propTypes = {
  children: PropTypes.node,
};

FlexItem.defaultProps = {
  children: null,
};

export default FlexItem;
