import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

const StyledP = styled.p`
  h1 + & {
    margin-top: -1rem;
  }
`;

const P = ({ children, ...props }) => {
  return <StyledP {...props}>{children}</StyledP>;
};

P.propTypes = {
  children: PropTypes.node.isRequired,
};

export default P;
