import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

const StyledH1 = styled.h1`
  display: flex;
  font-size: 2rem;

  & + form {
    margin-top: var(--space);
  }

  svg {
    padding-top: 5px;
    stroke-width: 1.4px;
  }
`;

const H1 = ({ children, ...props }) => {
  return <StyledH1 {...props}>{children}</StyledH1>;
};

H1.propTypes = {
  children: PropTypes.node.isRequired,
};

export default H1;
