import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

const StyledTableBody = styled.tbody``;

const TableBody = ({ children, ...props }) => {
  return <StyledTableBody {...props}>{children}</StyledTableBody>;
};

TableBody.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TableBody;
