import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import AppLayout from './components/AppLayout';
import Dashboard from './components/Dashboard';
import Authentication from './components/Authentication';
import Products from './components/Products';
import Product from './components/Product';
import CreateProduct from './components/CreateProduct';
import Customers from './components/Customers';
import Customer from './components/Customer';
import CreateCustomer from './components/CreateCustomer';
import Expenses from './components/Expenses';
import Expense from './components/Expense';
import CreateExpense from './components/CreateExpense';
import Sales from './components/Sales';
import CreateSale from './components/CreateSale';
import Sale from './components/Sale';
import WriteOffs from './components/WriteOffs';
import WriteOff from './components/WriteOff';
import CreateWriteOff from './components/CreateWriteOff';
import Checkout from './components/Checkout';
import useAuth from './stores/auth';
import RefreshWrapper from './components/RefreshWrapper';

const App = () => {
  const { token } = useAuth();

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/checkout/:isi">
          <Checkout />
        </Route>
        <Route path="/">
          <RefreshWrapper>
            <AppLayout>
              <Switch>
                <Route
                  path="/"
                  exact
                  render={(props) =>
                    token ? <Dashboard {...props} /> : <Redirect to="/auth" />
                  }
                />
                <Route
                  path="/products"
                  exact
                  render={(props) =>
                    token ? <Products {...props} /> : <Redirect to="/auth" />
                  }
                />
                <Route
                  path="/products/create"
                  exact
                  render={(props) =>
                    token ? (
                      <CreateProduct {...props} />
                    ) : (
                      <Redirect to="/auth" />
                    )
                  }
                />
                <Route
                  path="/products/:id"
                  exact
                  render={(props) =>
                    token ? <Product {...props} /> : <Redirect to="/auth" />
                  }
                />
                <Route
                  path="/customers"
                  exact
                  render={(props) =>
                    token ? <Customers {...props} /> : <Redirect to="/auth" />
                  }
                />
                <Route
                  path="/customers/create"
                  exact
                  render={(props) =>
                    token ? (
                      <CreateCustomer {...props} />
                    ) : (
                      <Redirect to="/auth" />
                    )
                  }
                />
                <Route
                  path="/customers/:id"
                  exact
                  render={(props) =>
                    token ? <Customer {...props} /> : <Redirect to="/auth" />
                  }
                />
                <Route
                  path="/expenses"
                  exact
                  render={(props) =>
                    token ? <Expenses {...props} /> : <Redirect to="/auth" />
                  }
                />
                <Route
                  path="/expenses/create"
                  exact
                  render={(props) =>
                    token ? (
                      <CreateExpense {...props} />
                    ) : (
                      <Redirect to="/auth" />
                    )
                  }
                />
                <Route
                  path="/expenses/:id"
                  exact
                  render={(props) =>
                    token ? <Expense {...props} /> : <Redirect to="/auth" />
                  }
                />
                <Route
                  path="/sales"
                  exact
                  render={(props) =>
                    token ? <Sales {...props} /> : <Redirect to="/auth" />
                  }
                />
                <Route
                  path="/sales/create"
                  exact
                  render={(props) =>
                    token ? <CreateSale {...props} /> : <Redirect to="/auth" />
                  }
                />
                <Route
                  path="/sales/:id"
                  exact
                  render={(props) =>
                    token ? <Sale {...props} /> : <Redirect to="/auth" />
                  }
                />
                <Route
                  path="/write-offs"
                  exact
                  render={(props) =>
                    token ? <WriteOffs {...props} /> : <Redirect to="/auth" />
                  }
                />
                <Route
                  path="/write-offs/create"
                  exact
                  render={(props) =>
                    token ? (
                      <CreateWriteOff {...props} />
                    ) : (
                      <Redirect to="/auth" />
                    )
                  }
                />
                <Route
                  path="/write-offs/:id"
                  exact
                  render={(props) =>
                    token ? <WriteOff {...props} /> : <Redirect to="/auth" />
                  }
                />
                <Route path="/auth" exact component={Authentication} />
              </Switch>
            </AppLayout>
          </RefreshWrapper>
        </Route>
      </Switch>
    </BrowserRouter>
  );
};

export default App;
