import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import SaleFormProduct from './SaleFormProduct';
import P from './elements/P';
import TableRow from './elements/TableRow';
import { TableItem } from './elements/Elements';

const SaleFormProducts = ({ locked }) => {
  const {
    values: { products },
  } = useFormikContext();

  return products.length ? (
    products.map((product) => (
      <SaleFormProduct key={product._id} product={product} locked={locked} />
    ))
  ) : (
    <TableRow>
      <TableItem style={{ textAlign: 'center' }} colSpan="5">
        <P style={{ fontWeight: 500, fontSize: '0.9rem' }}>
          No products selected for this sale.
        </P>
      </TableItem>
    </TableRow>
  );
};

SaleFormProducts.propTypes = {
  locked: PropTypes.bool,
};

SaleFormProducts.defaultProps = {
  locked: false,
};

export default SaleFormProducts;
