import create from 'zustand';
import { USER_STORAGE_KEY, TOKEN_STORAGE_KEY } from '../constants';

const useAuth = create((set) => ({
  user: JSON.parse(localStorage.getItem(USER_STORAGE_KEY)),
  setUser: (user) => {
    localStorage.setItem(USER_STORAGE_KEY, JSON.stringify(user));
    set(() => ({ user }));
  },
  removeUser: () => {
    localStorage.removeItem(USER_STORAGE_KEY);
    set(() => ({ user: null }));
  },
  token: JSON.parse(localStorage.getItem(TOKEN_STORAGE_KEY)),
  setToken: (token) => {
    localStorage.setItem(TOKEN_STORAGE_KEY, JSON.stringify(token));
    set(() => ({ token }));
  },
  removeToken: () => {
    localStorage.removeItem(TOKEN_STORAGE_KEY);
    set(() => ({ token: null }));
  },
}));

export default useAuth;
