import PropTypes from 'prop-types';
import { Field, ErrorMessage } from 'formik';
import styled from 'styled-components/macro';
import FormError from './FormError';
import Label from './Label';

const StyledField = styled.div`
  &:last-of-type {
    margin-bottom: var(--space);
  }

  & + & {
    margin-top: var(--space);
  }
`;

const FormikField = ({
  errors,
  touched,
  name,
  label,
  onBlur,
  children,
  validateField,
  setFieldTouched,
  ...props
}) => {
  return (
    <StyledField>
      <Label htmlFor={name}>{label}</Label>
      <Field
        {...props}
        id={name}
        name={name}
        hasError={touched[name] && errors[name]}
        onBlur={() => {
          if (onBlur) onBlur();
        }}
      >
        {children}
      </Field>
      <ErrorMessage name={name} component={FormError} />
    </StyledField>
  );
};

FormikField.propTypes = {
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  children: PropTypes.node,
  validateField: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
};

FormikField.defaultProps = {
  onBlur: null,
  children: null,
};

export default FormikField;
