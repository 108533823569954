import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { humanReadableDollars } from '../utils/helpers';
import Client from '../utils/network';
import {
  Button,
  FlexContainer,
  FlexItem,
  H1,
  P,
  Table,
  TableBody,
  TableHead,
  TableHeadItem,
  TableItem,
  TableRow,
} from './elements/Elements';
import TableSkeleton from './skeletons/TableSkeleton';
import ContextualFilter from './ContextualFilter';
import DateFilter from './DateFilter';

const Expenses = () => {
  const [filtered, setFiltered] = useState();
  const [dateRange, setDateRange] = useState();
  const [contextualFilter, setContextualFilter] = useState();

  useEffect(() => {
    (async () => {
      try {
        if (!dateRange || !contextualFilter) return;

        const { start, end } = dateRange;
        const filter = contextualFilter;

        setFiltered(
          await Client.get(
            `/getExpenses?start=${start}&end=${end}&filter=${filter}`,
          ),
        );
      } catch (error) {
        toast.error(error);
      }
    })();
  }, [dateRange, contextualFilter]);

  return (
    <>
      <FlexContainer style={{ marginBottom: '2rem' }}>
        <FlexItem>
          <H1>Expenses</H1>
        </FlexItem>
        <FlexItem
          flex="0"
          marginAuto
          alignRight
          style={{ marginRight: '1rem' }}
        >
          <DateFilter
            storageKey="_trouve_date_filter_expenses"
            handleChange={({ start, end }) => {
              if (!start || !end) return;
              setDateRange({ start, end });
            }}
          />
        </FlexItem>
        <FlexItem flex="0" marginAuto alignRight>
          <Button to="/expenses/create">Create Expense</Button>
        </FlexItem>
      </FlexContainer>
      <div style={{ marginBottom: '2rem' }}>
        <ContextualFilter
          storageKey="_trouve_contextual_filter_expenses"
          options={[
            { label: 'All Expenses', value: 'all' },
            { label: 'Auto-Generated', value: 'generated' },
            { label: 'Other', value: 'ungenerated' },
          ]}
          handleChange={setContextualFilter}
        />
      </div>
      {filtered && !!filtered.length && (
        <Table>
          <TableHead>
            <TableRow>
              <TableHeadItem>Date</TableHeadItem>
              <TableHeadItem>Description</TableHeadItem>
              <TableHeadItem>Merchant</TableHeadItem>
              <TableHeadItem>Category</TableHeadItem>
              <TableHeadItem style={{ textAlign: 'right' }}>Cost</TableHeadItem>
            </TableRow>
          </TableHead>
          <TableBody>
            {filtered.map(
              ({
                _id: id,
                incurredAt,
                description,
                merchant,
                category,
                cost,
                generated,
              }) => (
                <TableRow key={id}>
                  <TableItem>
                    {new Intl.DateTimeFormat('en-US', {
                      month: '2-digit',
                      day: '2-digit',
                      year: 'numeric',
                    }).format(new Date(incurredAt))}
                  </TableItem>
                  <TableItem>{description}</TableItem>
                  <TableItem>{merchant}</TableItem>
                  <TableItem>{category}</TableItem>
                  <TableItem style={{ textAlign: 'right' }}>
                    {humanReadableDollars(cost / 100)}
                  </TableItem>
                  <TableItem style={{ textAlign: 'right' }}>
                    <Button tiny to={`/expenses/${id}`}>
                      {generated ? 'View' : 'Edit'}
                    </Button>
                  </TableItem>
                </TableRow>
              ),
            )}
          </TableBody>
        </Table>
      )}
      {!filtered && <TableSkeleton />}
      {filtered && !filtered.length && (
        <P>
          No expenses found. Try adjusting your date and other filters or create
          your first expense.
        </P>
      )}
    </>
  );
};

export default Expenses;
