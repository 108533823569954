import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

const StyledFormError = styled.p`
  margin: 0;
  padding: 5px 0.25rem 0 0.25rem;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1.2;
  color: var(--error);
`;

const FormError = ({ children }) => {
  return <StyledFormError>{children}</StyledFormError>;
};

FormError.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string])
    .isRequired,
};

export default FormError;
