import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

const StyledH3 = styled.h3`
  font-weight: 600;

  svg {
    margin-right: 10px;
    font-size: 0.8em;
    color: rgba(var(--primary-purple-rgb), 0.5);
    stroke-width: 2.5px;
  }
`;

const H3 = ({ children, ...props }) => {
  return <StyledH3 {...props}>{children}</StyledH3>;
};

H3.propTypes = {
  children: PropTypes.node.isRequired,
};

export default H3;
