import { useEffect } from 'react';

const useCloseSomething = (state, setState) => {
  useEffect(() => {
    const closeOnEscape = (e) => {
      if (!e.keyCode && state) setState(false);
      if (e.keyCode === 27) {
        setState(false);
      }
    };

    window.addEventListener('keydown', closeOnEscape);
    window.addEventListener('click', closeOnEscape);

    return () => {
      window.removeEventListener('keydown', closeOnEscape);
      window.removeEventListener('click', closeOnEscape);
    };
  }, [state]);
};

export default useCloseSomething;
