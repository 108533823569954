// Used for displaying stored cents
export const humanReadableDollars = (amount, fractionDigits = 2) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
    currency: 'USD',
  }).format(amount);
};

// Used for accepting and cleaning up user-inputted currency string into text box
export const formatMoney = (amount, round) => {
  let a = amount;
  if (!a) a = '0';
  a = a.toString();
  a = a.replace(/,/g, '');
  if (a.split('')[0] === '$') a = a.substr(1);
  if (Number.isNaN(parseFloat(a))) a = '0';
  a = parseFloat(a).toFixed(2);
  if (round) a = Math.round(a);
  return humanReadableDollars(a);
};

// Used for accepting a formatted string from formatMoney ($255.55) and returning cents (25555)
export const getCents = (formattedString) => {
  return parseFloat((formattedString || '0').replace(/[^0-9]/g, ''));
};

export const calculateSaleTotal = ({
  products,
  shippingPrice,
  appliedCredit,
}) => {
  let calculatedTotal =
    products.reduce((a, { price }) => a + price, 0) +
    getCents(shippingPrice) -
    getCents(appliedCredit);

  if (calculatedTotal < 0) calculatedTotal = 0;

  return calculatedTotal;
};
