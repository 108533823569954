import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';
import Client from '../utils/network';
import {
  Input,
  FormikField,
  Textarea,
  Select,
  InputGroup,
  FlexItem,
  Button,
  FlexContainer,
} from './elements/Elements';
import { formatMoney, getCents, humanReadableDollars } from '../utils/helpers';
import { CATEGORIES, SALES_TAX_RATES } from '../constants';
import ResourceSection from './ResourceSection';

const SubTotal = styled.div`
  font-size: 1.5rem;
  font-weight: 500;
  text-align: right;

  span {
    display: block;
    font-size: 0.9rem;
    font-weight: 500;
  }
`;

const ProductForm = ({ data }) => {
  const { push } = useHistory();

  return (
    <ResourceSection>
      <Formik
        initialValues={{
          name: data?.name || '',
          notes: data?.notes || '',
          cost: data?.cost ? humanReadableDollars(data.cost / 100) : '',
          price: data?.price ? humanReadableDollars(data.price / 100) : '',
          salesTaxRate: data?.salesTaxRate || SALES_TAX_RATES[0].value,
          category: data?.category || 'Select One',
        }}
        onSubmit={async (values) => {
          try {
            const { method, path, successPastVerb } = {
              method: data ? 'put' : 'post',
              path: data ? `/updateProduct?id=${data._id}` : '/createProduct',
              successPastVerb: data ? 'updated' : 'created',
            };

            await Client[method](path, values);

            toast.success(`Product successfully ${successPastVerb}.`);
            push('/products');
          } catch (error) {
            toast.error(error);
          }
        }}
        validationSchema={() =>
          Yup.object().shape({
            name: Yup.string().required('Please enter a name.'),
            notes: Yup.string(),
            cost: Yup.string().nullable().required('Please enter an amount.'),
            salesTaxRate: Yup.number()
              .oneOf(SALES_TAX_RATES.map(({ value }) => value))
              .required(),
            price: Yup.string().nullable(),
            category: Yup.string()
              .oneOf(CATEGORIES, 'Please choose a category.')
              .required('Please choose a category.'),
          })
        }
      >
        {(formProps) => {
          return (
            <Form>
              <InputGroup>
                <FlexItem>
                  <FormikField
                    {...formProps}
                    name="name"
                    as={Input}
                    type="text"
                    placeholder="Name"
                    label="Name"
                    disabled={!!data?.sale || !!data?.writeOff}
                  />
                </FlexItem>
                <FlexItem>
                  <FormikField
                    {...formProps}
                    as={Select}
                    name="category"
                    label="Category"
                    disabled={!!data?.sale || !!data?.writeOff}
                  >
                    <option disabled value="Select One">
                      Select One
                    </option>
                    {CATEGORIES.map((category) => (
                      <option key={category} value={category}>
                        {category}
                      </option>
                    ))}
                  </FormikField>
                </FlexItem>
              </InputGroup>
              <FormikField
                {...formProps}
                name="notes"
                as={Textarea}
                rows={2}
                placeholder="Optional Notes"
                label="Notes"
                disabled={!!data?.sale || !!data?.writeOff}
              />
              <InputGroup>
                <FlexItem>
                  <FormikField
                    {...formProps}
                    name="cost"
                    as={Input}
                    placeholder="$0.00"
                    label="Cost"
                    type="text"
                    onBlur={() =>
                      formProps.setFieldValue(
                        'cost',
                        formatMoney(formProps.values.cost),
                        false,
                      )
                    }
                    onClick={() => {
                      document.execCommand('selectall', null, false);
                    }}
                    disabled={!!data?.sale || !!data?.writeOff}
                  />
                </FlexItem>
                <FlexItem>
                  <FormikField
                    {...formProps}
                    as={Select}
                    name="salesTaxRate"
                    label="Sales Tax Rate"
                    disabled={!!data?.sale || !!data?.writeOff}
                  >
                    <option disabled value="Select One">
                      Select One
                    </option>
                    {SALES_TAX_RATES.map(({ label, value }) => (
                      <option key={label} value={value}>
                        {label}
                      </option>
                    ))}
                  </FormikField>
                </FlexItem>
                <FlexItem>
                  <FormikField
                    {...formProps}
                    name="price"
                    as={Input}
                    placeholder="$0.00"
                    label="Price"
                    type="text"
                    onBlur={() =>
                      formProps.setFieldValue(
                        'price',
                        formatMoney(formProps.values.price),
                        false,
                      )
                    }
                    onClick={() => {
                      document.execCommand('selectall', null, false);
                    }}
                    disabled={!!data?.sale || data?.writeOff}
                  />
                </FlexItem>
              </InputGroup>
              <FlexContainer>
                {!data?.sale && !data?.writeOff && (
                  <FlexItem marginAuto>
                    <Button loading={formProps.isSubmitting} type="submit">
                      Save Product
                    </Button>
                  </FlexItem>
                )}
                <FlexItem marginAuto>
                  <SubTotal
                    style={{
                      textAlign: (data?.sale || data?.writeOff) && 'left',
                    }}
                  >
                    <span>Total Cost (Incl. Tax)</span>
                    {humanReadableDollars(
                      (getCents(formProps.values.cost) +
                        getCents(
                          `${Math.round(
                            getCents(formProps.values.cost) *
                              formProps.values.salesTaxRate,
                          )}`,
                        )) /
                        100,
                    )}
                  </SubTotal>
                </FlexItem>
                <FlexItem marginAuto>
                  <SubTotal>
                    <span>
                      {data?.sale ? 'Gross Profit' : 'Potential Gross Profit'}
                    </span>
                    {humanReadableDollars(
                      (getCents(formProps.values.price) -
                        getCents(formProps.values.cost) -
                        getCents(
                          `${Math.round(
                            getCents(formProps.values.cost) *
                              formProps.values.salesTaxRate,
                          )}`,
                        )) /
                        100,
                    )}
                  </SubTotal>
                </FlexItem>
              </FlexContainer>
            </Form>
          );
        }}
      </Formik>
    </ResourceSection>
  );
};

ProductForm.propTypes = {
  data: PropTypes.object,
};

ProductForm.defaultProps = {
  data: null,
};

export default ProductForm;
