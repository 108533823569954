import { useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import styled from 'styled-components/macro';
import { useFormikContext } from 'formik';
import { TableRow, TableItem, Button } from './elements/Elements';
import { humanReadableDollars, formatMoney } from '../utils/helpers';
import Client from '../utils/network';

const EditableTableItem = styled(TableItem)`
  position: relative;
  transition: var(--input-transitions);
  box-shadow: inset 0 0 0 2px transparent;
  outline: none;
  ${({ loading }) => {
    return (
      loading &&
      `
      color: var(--gray);
    `
    );
  }}

  &:focus {
    background-color: var(--white);
    box-shadow: inset 0 0 0 2px var(--blue);
  }

  span {
    border-bottom: 1px dashed var(--gray);
    ${({ locked }) => locked && 'border-bottom: none;'}
  }
`;

const SaleFormProduct = ({ product, locked }) => {
  const { _id: id, name, cost, salesTax, price } = product;
  const { values, setFieldValue } = useFormikContext();
  const [updatedPrice, setUpdatedPrice] = useState(
    humanReadableDollars(price / 100 || 0),
  );
  const [loading, setLoading] = useState(false);

  const updatePrice = async (text) => {
    try {
      setLoading(true);

      setUpdatedPrice(formatMoney(text));

      const updated = await Client.put(`/updateProduct?id=${id}`, {
        price: formatMoney(text),
      });

      const { products } = values;

      const index = products
        .map((productToUpdate) => productToUpdate._id)
        .indexOf(updated._id);

      products.splice(index, 1, updated);

      setFieldValue('products', [...products]);
      setLoading(false);
    } catch (error) {
      toast.error(error);
      setLoading(false);
    }
  };

  const removeProduct = () => {
    const index = values.products
      .map((productToRemove) => productToRemove._id)
      .indexOf(id);

    const { products } = values;

    products.splice(index, 1);

    setFieldValue('products', [...products]);
  };

  return (
    <TableRow key={id}>
      <TableItem>{name}</TableItem>
      <TableItem style={{ textAlign: 'right' }}>
        {humanReadableDollars((cost + salesTax) / 100)}
      </TableItem>
      <EditableTableItem
        id={`editable-${id}`}
        contentEditable={!locked}
        suppressContentEditableWarning
        onBlur={({ target: { innerText } }) => {
          setUpdatedPrice('');
          setTimeout(() => {
            updatePrice(innerText);
          }, 0);
        }}
        onClick={() => {
          if (locked) return;
          document.execCommand('selectall', null, false);
        }}
        style={{ textAlign: 'right', width: '150px' }}
        loading={loading ? 'yep' : ''}
        locked={locked ? 'yep' : ''}
      >
        <span>{updatedPrice}</span>
      </EditableTableItem>
      <TableItem>
        <Button
          style={{ marginLeft: 'auto' }}
          tiny
          disabled={locked}
          type="button"
          onClick={removeProduct}
        >
          Remove
        </Button>
      </TableItem>
    </TableRow>
  );
};

SaleFormProduct.propTypes = {
  product: PropTypes.object.isRequired,
  locked: PropTypes.bool,
};

SaleFormProduct.defaultProps = {
  locked: false,
};

export default SaleFormProduct;
