import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

const StyledFormHelper = styled.p`
  margin: 0;
  padding: 5px 0.25rem 0 0.25rem;
  font-size: 0.75rem;
  line-height: 1.2;
`;

const FormHelper = ({ children }) => {
  return <StyledFormHelper>{children}</StyledFormHelper>;
};

FormHelper.propTypes = {
  children: PropTypes.element.isRequired,
};

export default FormHelper;
