import { useState } from 'react';
import styled from 'styled-components/macro';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { Formik, Form, ErrorMessage } from 'formik';
import { FiPlus } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import Client from '../utils/network';
import {
  FormikField,
  Textarea,
  FlexItem,
  Button,
  Table,
  TableHead,
  TableHeadItem,
  TableBody,
  TableRow,
  FlexContainer,
  FormError,
} from './elements/Elements';
import { humanReadableDollars } from '../utils/helpers';
import WriteOffFormProducts from './WriteOffFormProducts';
import ProductSelectModal from './ProductSelectModal';
import ResourceSection from './ResourceSection';

const SubTotal = styled.div`
  font-size: 1.5rem;
  font-weight: 500;
  text-align: right;

  span {
    display: block;
    font-size: 0.9rem;
    font-weight: 500;
  }
`;

const WriteOffForm = ({ data }) => {
  const [showProductSelect, setShowProductSelect] = useState(false);
  const { push } = useHistory();

  return (
    <ResourceSection>
      <Formik
        initialValues={{
          products: data?.products || [],
          notes: data?.notes || '',
        }}
        onSubmit={async (values) => {
          try {
            const { method, path, successPastVerb } = {
              method: data ? 'put' : 'post',
              path: data ? `/updateWriteOff?id=${data._id}` : '/createWriteOff',
              successPastVerb: data ? 'updated' : 'created',
            };

            await Client[method](path, {
              ...values,
              products: values.products.map(({ _id: id }) => id),
            });

            toast.success(`Write off successfully ${successPastVerb}.`);
            push('/write-offs');
          } catch (error) {
            toast.error(error);
          }
        }}
        validationSchema={() =>
          Yup.object().shape({
            products: Yup.array()
              .of(Yup.object())
              .required()
              .min(1, 'Please add at least one product.'),
            notes: Yup.string(),
          })
        }
      >
        {(formProps) => {
          return (
            <Form>
              <ProductSelectModal
                isOpen={showProductSelect}
                close={() => setShowProductSelect(false)}
              />
              <Table>
                <TableHead>
                  <TableRow>
                    <TableHeadItem>Name</TableHeadItem>
                    <TableHeadItem
                      style={{ textAlign: 'right', width: '150px' }}
                    >
                      Cost
                    </TableHeadItem>
                    <TableHeadItem
                      style={{ textAlign: 'right', width: '150px' }}
                    >
                      Price
                    </TableHeadItem>
                    <TableHeadItem />
                  </TableRow>
                </TableHead>
                <TableBody>
                  <WriteOffFormProducts />
                </TableBody>
              </Table>
              <Button
                style={{
                  marginTop: '1rem',
                }}
                small
                type="button"
                onClick={() => setShowProductSelect(true)}
              >
                <FiPlus />
                Add Product
              </Button>
              <ErrorMessage name="products" component={FormError} />
              <div style={{ marginTop: '2rem' }} />
              <FormikField
                {...formProps}
                name="notes"
                as={Textarea}
                rows={2}
                placeholder="Optional Notes"
                label="Notes"
              />
              <FlexContainer style={{ marginTop: '2rem' }}>
                <FlexItem marginAuto>
                  <Button loading={formProps.isSubmitting} type="submit">
                    Save Write Off
                  </Button>
                </FlexItem>
                <FlexItem marginAuto>
                  <SubTotal>
                    <span>Total Loss</span>-
                    {humanReadableDollars(
                      formProps.values.products.reduce(
                        (a, { cost, salesTax }) => a + cost + salesTax,
                        0,
                      ) / 100,
                    )}
                  </SubTotal>
                </FlexItem>
              </FlexContainer>
            </Form>
          );
        }}
      </Formik>
    </ResourceSection>
  );
};

WriteOffForm.propTypes = {
  data: PropTypes.object,
};

WriteOffForm.defaultProps = {
  data: null,
};

export default WriteOffForm;
