import { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { DateRangePicker } from 'react-dates';
import moment from 'moment';
import { FiArrowRight } from 'react-icons/fi';

const StyledArrowRightFi = styled(FiArrowRight)`
  margin: 3px 5px 0 5px;
`;

const CustomArrow = () => {
  return <StyledArrowRightFi />;
};

const DateFilterRange = ({ handleChange, initialStart, initialEnd }) => {
  const [focusedInput, setFocusedInput] = useState();
  const [start, setStart] = useState(moment(initialStart) || moment());
  const [end, setEnd] = useState(moment(initialEnd) || moment());

  return (
    <DateRangePicker
      startDate={start}
      startDateId="start"
      endDate={end}
      endDateId="end"
      onDatesChange={({ startDate, endDate }) => {
        setStart(startDate);
        setEnd(endDate);
        handleChange({ start: new Date(startDate), end: new Date(endDate) });
      }}
      focusedInput={focusedInput}
      onFocusChange={(focused) => setFocusedInput(focused)}
      customArrowIcon={<CustomArrow />}
      isOutsideRange={() => false}
    />
  );
};

DateFilterRange.propTypes = {
  initialStart: PropTypes.object.isRequired,
  initialEnd: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default DateFilterRange;
