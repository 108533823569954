import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

const StyledFlexContainer = styled.div`
  display: flex;
  ${({ column }) => column && 'flex-direction: column;'}
  ${({ collapse, collapseAt, collapseMarginFirstItem }) =>
    collapse &&
    `@media (max-width: ${
      collapseAt || 600
    }px) { flex-direction: column; & :first-child { margin-bottom: ${collapseMarginFirstItem}px; } }`}
`;

const FlexContainer = ({ children, ...props }) => {
  return <StyledFlexContainer {...props}>{children}</StyledFlexContainer>;
};

FlexContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FlexContainer;
