import { NavLink } from 'react-router-dom';
import { FiChevronRight } from 'react-icons/fi';
import ExpenseForm from './ExpenseForm';
import { H1 } from './elements/Elements';

const CreateExpense = () => {
  return (
    <div style={{ maxWidth: 'var(--narrow-max-width)' }}>
      <H1>
        <NavLink to="/expenses">Expenses</NavLink> <FiChevronRight /> Create
        Expense
      </H1>
      <ExpenseForm />
    </div>
  );
};

export default CreateExpense;
