import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { toast } from 'react-toastify';
import { useFormikContext } from 'formik';
import Fuse from 'fuse.js';
import { FiX } from 'react-icons/fi';
import Client from '../utils/network';
import { Input, LoadingIndicator } from './elements/Elements';

const MAX_RESULTS = 8;

const Wrapper = styled.div`
  max-width: 600px;
  margin: 0 auto;
`;

const Results = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
`;

const ItemWrapper = styled.li`
  margin-top: 10px;
`;

const Item = styled.button`
  width: 100%;
  background-color: var(--gray--background);
  border: none;
  padding: 1rem;
  border-radius: var(--border-radius);
  text-align: left;
  outline: none;

  &:active,
  &:focus {
    box-shadow: inset 0 0 0 2px var(--blue);
  }
`;

const Name = styled.span`
  display: block;
  font-weight: 500;
`;

const CloseWrapper = styled.div`
  position: fixed;
  top: var(--space);
  right: var(--space);
  background-color: var(--gray--background);
  border-radius: 100%;
  height: 75px;
  width: 75px;

  &:active,
  &:focus-within {
    box-shadow: inset 0 0 0 2px var(--blue);
  }

  @media (max-width: 1200px) {
    bottom: var(--space);
    top: initial;
  }
`;

const Close = styled.button`
  position: absolute;
  height: 75px;
  width: 75px;
  border: none;
  border-radius: 100%;
  background-color: transparent;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: none;

  svg {
    display: block;
    font-size: 1.5rem;
    stroke-width: 1.5px;
    opacity: 0.5;
    margin: 0 auto;
  }

  span {
    display: block;
    color: var(--gray);
    font-size: 0.75rem;
  }
`;

const CustomerSelect = ({ close }) => {
  const [fuse, setFuse] = useState();
  const [initial, setInitial] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    (async () => {
      try {
        const customers = await Client.get('/getCustomers');

        setInitial(customers);
        setFiltered(customers.slice(0, MAX_RESULTS));

        setFuse(
          new Fuse(customers, {
            keys: ['name', 'email'],
            threshold: 0.5,
          }),
        );
      } catch (error) {
        toast.error(error);
      }
    })();
  }, []);

  const search = ({ target: { value } }) => {
    if (!value) return setFiltered(initial.slice(0, MAX_RESULTS));
    return setFiltered(
      fuse
        .search(value)
        ?.map(({ item }) => item)
        .slice(0, MAX_RESULTS),
    );
  };

  return (
    <Wrapper>
      <Input
        autoFocus
        name="search"
        type="text"
        placeholder="Type to search"
        onChange={search}
      />
      {filtered.length ? (
        <Results>
          {filtered.map((customer) => (
            <ItemWrapper key={customer._id}>
              <Item
                type="button"
                onClick={() => {
                  setFieldValue('customer', customer);
                  close();
                }}
              >
                <Name>{customer.name}</Name>
              </Item>
            </ItemWrapper>
          ))}
        </Results>
      ) : (
        <LoadingIndicator />
      )}
      <CloseWrapper>
        <Close type="button" onClick={() => close()}>
          <FiX />
          <span>Esc</span>
        </Close>
      </CloseWrapper>
    </Wrapper>
  );
};

CustomerSelect.propTypes = {
  close: PropTypes.func.isRequired,
};

export default CustomerSelect;
