import { NavLink } from 'react-router-dom';
import { FiChevronRight } from 'react-icons/fi';
import CustomerForm from './CustomerForm';
import { H1 } from './elements/Elements';

const CreateCustomer = () => {
  return (
    <div style={{ maxWidth: 'var(--narrow-max-width)' }}>
      <H1>
        <NavLink to="/customers">Customers</NavLink> <FiChevronRight /> Create
        Customer
      </H1>
      <CustomerForm />
    </div>
  );
};

export default CreateCustomer;
