import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

const StyledTableHead = styled.thead``;

const TableHead = ({ children, ...props }) => {
  return <StyledTableHead {...props}>{children}</StyledTableHead>;
};

TableHead.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TableHead;
