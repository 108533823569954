import ModalBase from './ModalBase';
import ProductSelect from './ProductSelect';

const ProductSelectModal = (props) => {
  return (
    <ModalBase {...props}>
      <ProductSelect {...props} />
    </ModalBase>
  );
};

export default ProductSelectModal;
