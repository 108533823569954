import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

const StyledInputGroup = styled.div`
  display: flex;
  flex-wrap: wrap;

  &:not(:first-child) {
    margin-top: var(--space);
  }

  &:last-child {
    margin-bottom: var(--space);
  }

  & > div + div {
    margin-left: 1rem;
  }

  & + & {
    margin-top: 0;
  }
`;

const InputGroup = ({ children, ...props }) => {
  return <StyledInputGroup {...props}>{children}</StyledInputGroup>;
};

InputGroup.propTypes = {
  children: PropTypes.node.isRequired,
};

export default InputGroup;
