import { useEffect, useState } from 'react';
import { NavLink, useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FiChevronRight } from 'react-icons/fi';
import Client from '../utils/network';
import H1 from './elements/H1';
import CustomerForm from './CustomerForm';
import { Button, LoadingIndicator } from './elements/Elements';
import ResourceSection from './ResourceSection';

const Customer = () => {
  const [data, setData] = useState();
  const [deleting, setDeleting] = useState();
  const { id } = useParams();
  const { push } = useHistory();

  useEffect(() => {
    (async () => {
      try {
        setData(await Client.get(`/getCustomer?id=${id}`));
      } catch (error) {
        toast.error(error);
      }
    })();
  }, [id]);

  const deleteRecord = async () => {
    try {
      setDeleting(true);

      if (!window.confirm('Please confirm to delete this customer.')) return;

      await Client.delete(`deleteCustomer?id=${id}`, { data: {} });

      toast.success('Customer successfully deleted.');
      setDeleting(false);
      push('/customers');
    } catch (error) {
      setDeleting(false);
      toast.error(error);
    }
  };

  if (!data) return <LoadingIndicator />;

  return (
    <div style={{ maxWidth: 'var(--narrow-max-width)' }}>
      <H1>
        <NavLink to="/customers">Customers</NavLink> <FiChevronRight /> Edit
        Customer
      </H1>
      <CustomerForm data={data} />
      <ResourceSection>
        <Button
          style={{ backgroundColor: 'var(--error)' }}
          onClick={deleteRecord}
          small
          loading={deleting}
        >
          Delete Customer
        </Button>
      </ResourceSection>
    </div>
  );
};

export default Customer;
