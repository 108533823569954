import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

const StyledTableRow = styled.tr`
  &:nth-child(even) {
    background-color: var(--gray--vague);
  }
`;

const TableRow = ({ children, ...props }) => {
  return <StyledTableRow {...props}>{children}</StyledTableRow>;
};

TableRow.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TableRow;
