import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

const Options = styled.div`
  display: inline-block;
  width: 100%;
  border-bottom: 1px solid var(--gray--background);
  border-radius: var(--border-radius);
`;

const Option = styled.button`
  background-color: transparent;
  border: none;
  border-bottom: 1px solid var(--gray--background);
  color: var(--gray);
  font-weight: 500;
  padding: 12px 18px;
  margin-bottom: -1px;

  &.active {
    background-color: var(--white);
    color: var(--blue);
    border-bottom: var(--input-border);
    border-color: var(--blue);
  }
`;

const ContextualFilter = ({ storageKey, options, handleChange }) => {
  const prev = localStorage.getItem(storageKey);
  const [option, setOption] = useState(prev || options[0].value);

  useEffect(() => {
    handleChange(option);
  }, []);

  return (
    <Options>
      {options.map(({ label, value }) => (
        <Option
          key={label}
          className={option === value ? 'active' : ''}
          onClick={() => {
            localStorage.setItem(storageKey, value);
            setOption(value);
            handleChange(value);
          }}
        >
          {label}
        </Option>
      ))}
    </Options>
  );
};

ContextualFilter.propTypes = {
  storageKey: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default ContextualFilter;
