import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { NavLink, useParams, useHistory } from 'react-router-dom';
import { FiChevronRight } from 'react-icons/fi';
import Client from '../utils/network';
import { Button, P, H1, LoadingIndicator } from './elements/Elements';
import WriteOffForm from './WriteOffForm';
import ResourceSection from './ResourceSection';

const WriteOff = () => {
  const [data, setData] = useState();
  const [deleting, setDeleting] = useState();
  const { id } = useParams();
  const { push } = useHistory();

  useEffect(() => {
    (async () => {
      try {
        setData(await Client.get(`/getWriteOff?id=${id}`));
      } catch (error) {
        toast.error(error);
      }
    })();
  }, [id]);

  const deleteWriteOff = async () => {
    try {
      setDeleting(true);

      if (!window.confirm('Please confirm to delete this write off.')) return;

      await Client.delete(`deleteWriteOff?id=${id}`, { data: {} });

      toast.success('Write off successfully deleted.');
      setDeleting(false);
      push('/write-offs');
    } catch (error) {
      setDeleting(false);
      toast.error(error);
    }
  };

  if (!data) return <LoadingIndicator />;

  return (
    <div style={{ maxWidth: 'var(--narrow-max-width)' }}>
      <H1>
        <NavLink to="/write-offs">Write Offs</NavLink> <FiChevronRight /> Write
        Off Details
      </H1>
      <WriteOffForm data={data} />
      <ResourceSection>
        <P>
          Deleting this write off will restore the unsold status to all
          associated products and remove the write off from being considered in
          reporting.
        </P>
        <Button
          style={{ backgroundColor: 'var(--error)' }}
          onClick={deleteWriteOff}
          small
          loading={deleting}
        >
          Delete Write Off
        </Button>
      </ResourceSection>
    </div>
  );
};

export default WriteOff;
