import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { FormikField, Select } from './elements/Elements';
import {
  APP_CHECKOUT_METHOD,
  APP_CREDIT_METHOD,
  PAYMENT_METHODS,
} from '../constants';
import { getCents, calculateSaleTotal } from '../utils/helpers';

const SaleFormPaymentStatusInput = ({ data }) => {
  const formProps = useFormikContext();
  const { values, setFieldValue } = formProps;

  useEffect(() => {
    const { paymentMethod } = values;

    const calculatedTotal = calculateSaleTotal(values);

    if (calculatedTotal && paymentMethod === APP_CREDIT_METHOD) {
      setFieldValue('paymentMethod', APP_CHECKOUT_METHOD);
      setFieldValue('paid', false);
    }

    if (!calculatedTotal) {
      setFieldValue('paymentMethod', APP_CREDIT_METHOD);
      setFieldValue('paid', true);
    }

    if (data?.paid) setFieldValue('paid', true);
  }, [values]);

  useEffect(() => {
    const { paymentMethod } = values;

    const calculatedTotal = calculateSaleTotal(values);

    if (calculatedTotal && paymentMethod !== APP_CREDIT_METHOD) {
      setFieldValue('paid', false);
    }
  }, [values.paymentMethod]);

  return (
    <>
      <FormikField
        {...formProps}
        as={Select}
        name="paymentMethod"
        label="Payment Method"
        disabled={!!data?.paid}
      >
        <option disabled value="Select One">
          Select One
        </option>
        {PAYMENT_METHODS.filter((paymentMethod) => {
          const calculatedTotal =
            formProps.values.products.reduce((a, { price }) => a + price, 0) +
            getCents(formProps.values.shippingPrice) -
            getCents(formProps.values.appliedCredit);

          if (!calculatedTotal) return paymentMethod === APP_CREDIT_METHOD;

          return paymentMethod !== APP_CREDIT_METHOD;
        }).map((paymentMethod) => (
          <option key={paymentMethod} value={paymentMethod}>
            {paymentMethod}
          </option>
        ))}
      </FormikField>
    </>
  );
};

SaleFormPaymentStatusInput.propTypes = {
  data: PropTypes.object,
};

SaleFormPaymentStatusInput.defaultProps = {
  data: null,
};

export default SaleFormPaymentStatusInput;
