import ModalBase from './ModalBase';
import CustomerSelect from './CustomerSelect';

const ProductSelectModal = (props) => {
  return (
    <ModalBase {...props}>
      <CustomerSelect {...props} />
    </ModalBase>
  );
};

export default ProductSelectModal;
