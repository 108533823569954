import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { FaChevronDown } from 'react-icons/fa';

const StyledSelectContainer = styled.div`
  font-size: 14px; // hack to match text input size.. no idea what's going on here
  position: relative;
  background-color: var(--input-background-color);
  ${({ disabled }) =>
    disabled && 'background-color: var(--input-background-color--disabled);'}
  border: var(--input-border);
  ${({ disabled }) => disabled && 'border: var(--input-border--disabled);'}
  ${({ hasError }) => hasError && 'border: var(--input-border--error);'}
  border-radius: var(--input-border-radius);
  box-shadow: var(--input-box-shadow);
  transition: var(--input-transitions);

  &:focus-within {
    border: var(--input-border-focus);
    ${({ hasError }) => hasError && 'border: var(--input-border-focus--error);'}
    box-shadow: var(--input-box-shadow-focus);
    ${({ hasError }) =>
      hasError && 'box-shadow: var(--input-box-shadow-focus--error);'}
  }

  svg {
    position: absolute;
    top: 52%;
    transform: translateY(-50%);
    right: 1rem;
    pointer-events: none;
    font-size: 0.65rem;
    fill: var(--blue);
  }

  & select[disabled] ~ svg {
    fill: var(--gray);
  }
`;

const StyledSelect = styled.select`
  display: block;
  width: 100%;
  border: none;
  background: transparent;
  margin: 0;
  line-height: var(--input-line-height);
  padding: var(--input-padding);
  border-radius: var(--input-border-radius);
  color: var(--input-color);

  &:disabled {
    color: var(--input-color--disabled) !important;
    opacity: 1;
  }
`;

const Select = ({ children, name, disabled, hasError, ...props }) => {
  return (
    <StyledSelectContainer disabled={disabled} hasError={hasError}>
      <StyledSelect id={name} {...props} name={name} disabled={disabled}>
        {children}
      </StyledSelect>
      <FaChevronDown />
    </StyledSelectContainer>
  );
};

Select.propTypes = {
  children: PropTypes.node.isRequired,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  hasError: PropTypes.bool,
};

Select.defaultProps = {
  name: null,
  disabled: false,
  hasError: false,
};

export default Select;
