import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

const StyledModalFooter = styled.div`
  display: flex;
  margin-top: var(--space);

  @media (max-width: 600px) {
    & button:last-of-type {
      margin-left: 1rem;
    }
  }
`;

const ModalFooter = ({ children, ...props }) => {
  return <StyledModalFooter {...props}>{children}</StyledModalFooter>;
};

ModalFooter.propTypes = {
  children: PropTypes.element.isRequired,
};

export default ModalFooter;
