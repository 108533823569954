import React from 'react';
import ReactDOM from 'react-dom';
import { ToastContainer, Slide } from 'react-toastify';
import { HelmetProvider } from 'react-helmet-async';
import KeyboardOnlyOutlines from '@moxy/react-keyboard-only-outlines';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import LogRocket from 'logrocket';
import App from './App';
import 'normalize.css';
import 'react-toastify/dist/ReactToastify.css';
import './assets/css/global.css';

LogRocket.init('djzgon/trouve-thrift');

ReactDOM.render(
  <React.StrictMode>
    <ToastContainer
      position="top-center"
      autoClose={3000}
      hideProgressBar
      transition={Slide}
    />
    <HelmetProvider>
      <KeyboardOnlyOutlines>
        <App />
      </KeyboardOnlyOutlines>
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
