/* eslint-disable */
import { useState } from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { SingleDatePicker } from 'react-dates';

const DatePickerWrapper = styled.div`
  width: var(--input-width);
  border-radius: var(--input-border-radius);
  border: var(--input-border);
  ${({ hasError }) => hasError && 'border: var(--input-border--error);'}
  background-color: var(--input-background-color);
  box-shadow: var(--input-box-shadow);
  transition: var(--input-transitions);
  line-height: var(--input-line-height);
  font-size: var(--input-font-size);
  color: var(--input-color);

  &:focus-within {
    background-color: var(--input-background-color-focus);
    border: var(--input-border-focus);
    ${({ hasError }) => hasError && 'border: var(--input-border-focus--error);'}
    box-shadow: var(--input-box-shadow-focus);
    ${({ hasError }) =>
      hasError && 'box-shadow: var(--input-box-shadow-focus--error);'}
  }

  &.disabled {
    background-color: var(--input-background-color--disabled);
    color: var(--input-color--disabled);
    border: var(--input-border--disabled);
  }

  .SingleDatePicker,
  .SingleDatePicker_1 {
    width: 100% !important;
  }

  .SingleDatePickerInput {
    width: 100% !important;
    border-radius: var(--border-radius);
    border: none;
    background-color: transparent;
  }

  .DateInput,
  .DateInput_1 {
    display: block;
    width: 100% !important;
  }

  .DateInput_input,
  .DateInput_input_1 {
    width: 100% !important;
    background-color: transparent;
    padding: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: inherit;
    border-bottom: none;
    border-radius: var(--border-radius);
    padding: var(--input-padding);

    &:disabled {
      color: var(--input-color--disabled);
      font-style: normal;
    }
  }
`;

const DateInput = ({ name, value, hasError, setFieldValue, disabled }) => {
  const [focused, setFocused] = useState(false);

  return (
    <DatePickerWrapper hasError={hasError} className={disabled && 'disabled'}>
      <SingleDatePicker
        date={value}
        focused={focused}
        onDateChange={(date) => setFieldValue(name, date)}
        onFocusChange={({ focused }) => setFocused(focused)}
        numberOfMonths={1}
        disabled={disabled}
        isOutsideRange={() => false}
      />
    </DatePickerWrapper>
  );
};

DateInput.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  hasError: PropTypes.bool,
  disabled: PropTypes.bool,
};

DateInput.defaultProps = {
  hasError: false,
  disabled: false,
};

export default DateInput;
