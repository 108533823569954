import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import Client from '../utils/network';
import {
  Input,
  FormikField,
  InputGroup,
  FlexItem,
  Button,
  Textarea,
} from './elements/Elements';
import { formatMoney, humanReadableDollars } from '../utils/helpers';
import ResourceSection from './ResourceSection';

const CustomerForm = ({ data }) => {
  const { push } = useHistory();
  const { search } = useLocation();

  const next = search.split('next=')[1];

  return (
    <ResourceSection>
      <Formik
        initialValues={{
          name: data?.name || '',
          email: data?.email || '',
          notes: data?.notes || '',
          addressLine1: data?.addressLine1 || '',
          addressLine2: data?.addressLine2 || '',
          city: data?.city || '',
          state: data?.state || '',
          zip: data?.zip || '',
          credit: data?.credit
            ? humanReadableDollars(data.credit / 100)
            : '$0.00',
        }}
        onSubmit={async (values) => {
          try {
            const { method, path, successPastVerb } = {
              method: data ? 'put' : 'post',
              path: data ? `/updateCustomer?id=${data._id}` : '/createCustomer',
              successPastVerb: data ? 'updated' : 'created',
            };

            await Client[method](path, {
              ...values,
              email: values.email.toLowerCase(),
            });

            toast.success(`Customer successfully ${successPastVerb}.`);
            push(next || '/customers');
          } catch (error) {
            toast.error(error);
          }
        }}
        validationSchema={() =>
          Yup.object().shape({
            name: Yup.string().required('Please enter a name.'),
            email: Yup.string().email().nullable(),
            notes: Yup.string().nullable(),
            addressLine1: Yup.string().nullable(),
            addressLine2: Yup.string().nullable(),
            city: Yup.string().nullable(),
            state: Yup.string().nullable(),
            zip: Yup.string().nullable(),
            credit: Yup.string().nullable(),
          })
        }
      >
        {(formProps) => {
          return (
            <Form>
              <InputGroup>
                <FlexItem>
                  <FormikField
                    {...formProps}
                    name="name"
                    as={Input}
                    type="text"
                    placeholder="Name"
                    label="Name"
                  />
                </FlexItem>
                <FlexItem>
                  <FormikField
                    {...formProps}
                    name="email"
                    as={Input}
                    type="text"
                    placeholder="Email"
                    label="Email Address"
                  />
                </FlexItem>
              </InputGroup>
              <FormikField
                {...formProps}
                name="notes"
                as={Textarea}
                rows={2}
                placeholder="Optional Notes"
                label="Notes"
              />
              <InputGroup>
                <FlexItem>
                  <FormikField
                    {...formProps}
                    name="addressLine1"
                    as={Input}
                    type="text"
                    placeholder="Street Address"
                    label="Address Line 1"
                  />
                </FlexItem>
                <FlexItem>
                  <FormikField
                    {...formProps}
                    name="addressLine2"
                    as={Input}
                    type="text"
                    placeholder="Apt./Suite"
                    label="Address Line 2"
                  />
                </FlexItem>
              </InputGroup>
              <InputGroup>
                <FlexItem>
                  <FormikField
                    {...formProps}
                    name="city"
                    as={Input}
                    type="text"
                    placeholder="Lancaster"
                    label="City"
                  />
                </FlexItem>
                <FlexItem>
                  <FormikField
                    {...formProps}
                    name="state"
                    as={Input}
                    type="text"
                    placeholder="PA"
                    label="State"
                  />
                </FlexItem>
                <FlexItem>
                  <FormikField
                    {...formProps}
                    name="zip"
                    as={Input}
                    type="text"
                    placeholder="17603"
                    label="Postal Code"
                  />
                </FlexItem>
              </InputGroup>
              <InputGroup>
                <FlexItem>
                  <FormikField
                    {...formProps}
                    name="credit"
                    as={Input}
                    placeholder="$0.00"
                    label="Credit Balance"
                    type="text"
                    onBlur={() =>
                      formProps.setFieldValue(
                        'credit',
                        formatMoney(formProps.values.credit),
                        false,
                      )
                    }
                    onClick={() => {
                      document.execCommand('selectall', null, false);
                    }}
                  />
                </FlexItem>
                <FlexItem />
              </InputGroup>
              <Button loading={formProps.isSubmitting} type="submit">
                Save Customer
              </Button>
            </Form>
          );
        }}
      </Formik>
    </ResourceSection>
  );
};

CustomerForm.propTypes = {
  data: PropTypes.object,
};

CustomerForm.defaultProps = {
  data: null,
};

export default CustomerForm;
