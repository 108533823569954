import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

const StyledH2 = styled.h2`
  svg {
    margin-right: 10px;
    font-size: 0.8em;
    color: rgba(var(--primary-purple-rgb), 0.5);
    stroke-width: 2.5px;
  }
`;

const H2 = ({ children, ...props }) => {
  return <StyledH2 {...props}>{children}</StyledH2>;
};

H2.propTypes = {
  children: PropTypes.node.isRequired,
};

export default H2;
