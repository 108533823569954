import React from 'react';
import styled from 'styled-components/macro';

const StyledHR = styled.hr`
  margin: 2rem 0;
  padding: 0;
  border: none;
  border-top: 1px solid var(--gray--border);
`;

const HR = ({ ...props }) => {
  return <StyledHR {...props} />;
};

export default HR;
