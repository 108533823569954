import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

const StyledViewHeader = styled.div`
  background-color: rgba(var(--primary-purple-rgb), 0.05);
  padding: calc(var(--small-cushion) * 1.25) var(--small-cushion);

  h1 {
    font-size: 1.75rem;
    margin: 0;
    line-height: 1.2;
  }

  p {
    margin: 0;
    color: rgba(var(--primary-purple-rgb), 0.8);
    font-weight: 500;
  }

  h1 + p,
  p + h1 {
    margin-top: 10px;
  }
`;

const Squisher = styled.div`
  margin: 0 auto;
  max-width: calc(
    ${({ maxwidth }) => maxwidth || '1000'}px - calc(var(--small-cushion) * 2)
  );
`;

const ViewHeader = ({ children, ...props }) => {
  return (
    <StyledViewHeader {...props}>
      <Squisher>{children}</Squisher>
    </StyledViewHeader>
  );
};

ViewHeader.propTypes = {
  children: PropTypes.element.isRequired,
};

export default ViewHeader;
