import { useEffect, useState } from 'react';
import { NavLink, useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FiChevronRight } from 'react-icons/fi';
import Client from '../utils/network';
import ProductForm from './ProductForm';
import {
  Button,
  FlexContainer,
  FlexItem,
  H1,
  LoadingIndicator,
  P,
} from './elements/Elements';
import ResourceSection from './ResourceSection';

const Product = () => {
  const [data, setData] = useState();
  const [deleting, setDeleting] = useState();
  const { id } = useParams();
  const { push } = useHistory();

  useEffect(() => {
    (async () => {
      try {
        setData(await Client.get(`/getProduct?id=${id}`));
      } catch (error) {
        toast.error(error);
      }
    })();
  }, [id]);

  const deleteRecord = async () => {
    try {
      setDeleting(true);

      if (!window.confirm('Please confirm to delete this product.')) return;

      await Client.delete(`deleteProduct?id=${id}`, { data: {} });

      toast.success('Product successfully deleted.');
      setDeleting(false);
      push('/products');
    } catch (error) {
      setDeleting(false);
      toast.error(error);
    }
  };

  if (!data) return <LoadingIndicator />;

  return (
    <div style={{ maxWidth: 'var(--narrow-max-width)' }}>
      <H1>
        <NavLink to="/products">Products</NavLink> <FiChevronRight /> Edit
        Product
      </H1>
      {(data.sale || data.writeOff) && (
        <ResourceSection>
          <FlexContainer>
            <FlexItem marginAuto>
              <P>
                Product {data.sale ? 'sold' : 'written off'} on{' '}
                {new Intl.DateTimeFormat('en-US', {
                  month: '2-digit',
                  day: '2-digit',
                  year: 'numeric',
                }).format(
                  new Date(data.sale?.createdAt || data.writeOff.createdAt),
                )}
                .
              </P>
            </FlexItem>
            <Button
              small
              to={`/${data.sale ? 'sales' : 'write-offs'}/${
                data.sale?._id || data.writeOff._id
              }`}
            >
              {data.sale ? 'Sale' : 'Write Off'} Details
            </Button>
          </FlexContainer>
        </ResourceSection>
      )}
      <ProductForm data={data} />
      {!data.sale && !data.writeOff && (
        <ResourceSection>
          <P>
            Deleting this product will remove it from your inventory and
            reporting.
          </P>
          <Button
            style={{ backgroundColor: 'var(--error)' }}
            onClick={deleteRecord}
            small
            loading={deleting}
          >
            Delete Product
          </Button>
        </ResourceSection>
      )}
    </div>
  );
};

export default Product;
