import axios from 'axios';
import { captureException } from '@sentry/browser';
import { API_BASE_URL } from '../constants';
import useAuth from '../stores/auth';

const client = axios.create({
  baseURL: API_BASE_URL,
});

client.interceptors.request.use(({ headers, ...config }) => {
  const { token } = useAuth.getState();

  return {
    ...config,
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : undefined,
    },
  };
});

client.interceptors.response.use(
  ({ data }) => {
    return data;
  },
  async (error) => {
    // const { config } = error;

    // Retry a failed get request once
    // if (config.method === 'get' && !config.isRetry) {
    //   config.isRetry = true;

    //   return client(config);
    // }

    // Send 500s to Sentry
    if (error.response?.statusCode === 500) {
      captureException({
        ...error,
        requestConfig: error.config,
        response: error.response,
      });
    }

    throw typeof error.response.data === 'string'
      ? error.response.data
      : error.response.data.message;
  },
);

export default client;
