import { useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import Button from './elements/Button';
import Client from '../utils/network';
import Label from './elements/Label';
import CardDetails from './CardDetails';
import { FlexContainer } from './elements/Elements';

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      fontFamily: 'monospace',
      fontSize: '16px',
      '::placeholder': {
        color: 'rgba(0, 0, 0, 0.3)',
      },
    },
  },
};
const CheckoutForm = ({ total, saleId, existingMethod, setPaid }) => {
  const [isSubmitting, setIsSubmitting] = useState();
  const [useExistingMethod, setUseExistingMethod] = useState(existingMethod);
  const stripe = useStripe();
  const elements = useElements();

  const pay = async () => {
    setIsSubmitting(true);

    try {
      if (useExistingMethod) {
        if (!stripe) {
          setIsSubmitting(false);
          return;
        }

        await Client.post('/checkout', {
          saleId,
        });
      } else {
        if (!stripe || !elements) {
          setIsSubmitting(false);
          return;
        }

        const card = elements.getElement(CardElement);

        const { token } = await stripe.createToken(card);

        if (!token) {
          setIsSubmitting(false);
          return;
        }

        const { paymentIntent } = await Client.post('/checkout', {
          saleId,
          token,
        });

        await Client.put(`/updateSalePaid?id=${saleId}`, { paid: true });

        const { error } = await stripe.confirmCardPayment(
          paymentIntent.client_secret,
          {
            payment_method: {
              card,
            },
          },
        );

        if (error) throw error.message;
      }

      setIsSubmitting(false);
      setPaid(true);
    } catch (error) {
      await Client.put(`/updateSalePaid?id=${saleId}`, { paid: false });
      toast.error(error);
      setIsSubmitting(false);
    }
  };
  return (
    <>
      {useExistingMethod ? (
        <CardDetails card={existingMethod} />
      ) : (
        <>
          <Label htmlFor="cardnumber">Debit/Credit Card</Label>
          <CardElement options={CARD_ELEMENT_OPTIONS} />
        </>
      )}
      <FlexContainer>
        <Button
          style={{ marginTop: '1rem' }}
          onClick={pay}
          loading={isSubmitting}
        >
          Pay {total}
        </Button>
        {existingMethod && (
          <Button
            small
            style={{ margin: '1rem 0 auto auto' }}
            onClick={() =>
              setUseExistingMethod(useExistingMethod ? null : existingMethod)
            }
          >
            {useExistingMethod ? 'Replace' : 'Use Saved'}
          </Button>
        )}
      </FlexContainer>
    </>
  );
};

CheckoutForm.propTypes = {
  total: PropTypes.string.isRequired,
  saleId: PropTypes.string.isRequired,
  setPaid: PropTypes.func.isRequired,
  existingMethod: PropTypes.object,
};

CheckoutForm.defaultProps = {
  existingMethod: null,
};

export default CheckoutForm;
