import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import Fuse from 'fuse.js';
import Client from '../utils/network';
import {
  Button,
  FlexContainer,
  FlexItem,
  H1,
  Input,
  Table,
  TableBody,
  TableHead,
  TableHeadItem,
  TableItem,
  TableRow,
} from './elements/Elements';
import TableSkeleton from './skeletons/TableSkeleton';
import { humanReadableDollars } from '../utils/helpers';

const MAX_RESULTS = 999;

const Customers = () => {
  const [fuse, setFuse] = useState();
  const [initial, setInitial] = useState();
  const [filtered, setFiltered] = useState();

  useEffect(() => {
    (async () => {
      try {
        const customers = await Client.get('/getCustomers');

        setInitial(customers);
        setFiltered(customers.slice(0, MAX_RESULTS));

        setFuse(
          new Fuse(customers, {
            keys: ['name', 'email', 'city', 'state'],
            threshold: 0.5,
          }),
        );
      } catch (error) {
        toast.error(error);
      }
    })();
  }, []);

  const search = ({ target: { value } }) => {
    if (!value) return setFiltered(initial.slice(0, MAX_RESULTS));
    return setFiltered(
      fuse
        .search(value)
        ?.map(({ item }) => item)
        .slice(0, MAX_RESULTS),
    );
  };

  return (
    <>
      <FlexContainer style={{ marginBottom: '2rem' }}>
        <FlexItem>
          <H1>Customers</H1>
        </FlexItem>
        <FlexItem marginAuto alignRight>
          <Button to="/customers/create">Create Customer</Button>
        </FlexItem>
      </FlexContainer>
      <FlexContainer style={{ marginBottom: '2rem' }}>
        <FlexItem marginAuto>
          <Input
            style={{ maxWidth: '400px' }}
            name="search"
            type="text"
            placeholder="Type to search"
            onChange={search}
            autoFocus
          />
        </FlexItem>
        <FlexItem marginAuto alignRight />
      </FlexContainer>
      {filtered ? (
        <Table>
          <TableHead>
            <TableRow>
              <TableHeadItem>Name</TableHeadItem>
              <TableHeadItem>Email</TableHeadItem>
              <TableHeadItem>Location</TableHeadItem>
              <TableHeadItem style={{ textAlign: 'right' }}>
                Lifetime Sales
              </TableHeadItem>
            </TableRow>
          </TableHead>
          <TableBody>
            {filtered.map(({ _id: id, name, email, city, state, sales }) => {
              return (
                <TableRow key={id}>
                  <TableItem>{name}</TableItem>
                  <TableItem>{email}</TableItem>
                  <TableItem>
                    {city && state ? `${city}, ${state}` : ''}
                  </TableItem>
                  <TableItem style={{ textAlign: 'right' }}>
                    {humanReadableDollars(
                      sales.reduce(
                        (a, { products }) =>
                          a + products.reduce((b, { price }) => b + price, 0),
                        0,
                      ) / 100,
                    )}
                  </TableItem>
                  <TableItem style={{ textAlign: 'right' }}>
                    <Button tiny to={`/customers/${id}`}>
                      Edit
                    </Button>
                  </TableItem>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      ) : (
        <TableSkeleton />
      )}
    </>
  );
};

export default Customers;
