/* eslint-disable react/no-array-index-key */
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { times } from 'lodash';
import { ReactComponent as VisaLogo } from '../assets/img/card-brands/visa.svg';
import { ReactComponent as AmericanExpressLogo } from '../assets/img/card-brands/american-express.svg';
import { ReactComponent as MastercardLogo } from '../assets/img/card-brands/mastercard.svg';
import { ReactComponent as DiscoverLogo } from '../assets/img/card-brands/discover.svg';

const CardWrapper = styled.div`
  display: flex;
  background: var(--gray--background);
  border-radius: 0.25rem;
  padding: 1rem;
  letter-spacing: 0.5px;
`;

const FlexItem = styled.div`
  flex: 1;
  margin: auto 0;
`;

const CardBrand = styled.div`
  flex: 1;

  svg {
    font-size: 3.5rem;
    color: var(--gray);
  }
`;

const CardNumber = styled.span`
  display: block;
  font-weight: 500;
  text-align: right;
`;

const Expiration = styled.span`
  display: block;
  font-weight: 300;
  font-size: 0.9rem;
  text-align: right;
  margin-top: 5px;
`;

const ObfuscatedBlock = styled.span`
  padding-right: 8px;
  font-size: 1.4rem;
  line-height: 0.1;
  color: var(--gray);

  @media (max-width: 475px) {
    display: none;
  }
`;

const ObfuscatedNumberPattern = ({ pattern }) =>
  pattern.map((count, index) => {
    return (
      <ObfuscatedBlock key={index}>
        {times(count, (timesIndex) => (
          <span key={timesIndex}>&bull;</span>
        ))}
      </ObfuscatedBlock>
    );
  });

ObfuscatedNumberPattern.propTypes = {
  pattern: PropTypes.array.isRequired,
};

const CardDetails = ({ card }) => {
  if (!card) return null;

  const { last4, exp_month: expMonth, exp_year: expYear, brand } = card;

  return (
    <CardWrapper>
      <FlexItem>
        <CardBrand>
          {brand === 'amex' && <AmericanExpressLogo />}
          {brand === 'visa' && <VisaLogo />}
          {brand === 'mastercard' && <MastercardLogo />}
          {brand === 'discover' && <DiscoverLogo />}
        </CardBrand>
      </FlexItem>
      <FlexItem>
        <CardNumber>
          <ObfuscatedNumberPattern
            pattern={brand === 'amex' ? [4, 6, 1] : [4, 4, 4]}
          />
          {`000${last4}`.slice(-4)}
        </CardNumber>
        <Expiration>
          Exp. {`0${expMonth.toString()}`.slice(-2)}/{expYear}
        </Expiration>
      </FlexItem>
    </CardWrapper>
  );
};

CardDetails.propTypes = {
  card: PropTypes.object.isRequired,
};

export default CardDetails;
