import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

const StyledModalHeader = styled.div`
  margin-bottom: var(--space);

  h2 {
    margin-bottom: 10px;
  }

  p {
    margin: 0;
    font-size: 0.9rem;
    color: rgba(var(--primary-purple-rgb), 0.9);
  }
`;

const ModalHeader = ({ children, ...props }) => {
  return <StyledModalHeader {...props}>{children}</StyledModalHeader>;
};

ModalHeader.propTypes = {
  children: PropTypes.element.isRequired,
};

export default ModalHeader;
