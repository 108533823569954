import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import Client from '../utils/network';
import {
  Button,
  FlexContainer,
  FlexItem,
  H1,
  P,
  Table,
  TableBody,
  TableHead,
  TableHeadItem,
  TableRow,
} from './elements/Elements';
import WriteOffsRow from './WriteOffsRow';
import TableSkeleton from './skeletons/TableSkeleton';
import DateFilter from './DateFilter';

const Customers = () => {
  const [filtered, setFiltered] = useState();
  const [dateRange, setDateRange] = useState();

  useEffect(() => {
    (async () => {
      try {
        if (!dateRange) return;

        const { start, end } = dateRange;

        setFiltered(
          await Client.get(`/getWriteOffs?start=${start}&end=${end}`),
        );
      } catch (error) {
        toast.error(error);
      }
    })();
  }, [dateRange]);

  return (
    <>
      <FlexContainer style={{ marginBottom: '2rem' }}>
        <FlexItem>
          <H1>Write Offs</H1>
        </FlexItem>
        <FlexItem
          flex="0"
          marginAuto
          alignRight
          style={{ marginRight: '1rem' }}
        >
          <DateFilter
            storageKey="_trouve_date_filter_write_offs"
            handleChange={({ start, end }) => {
              if (!start || !end) return;
              setDateRange({ start, end });
            }}
          />
        </FlexItem>
        <FlexItem flex="0" marginAuto alignRight>
          <Button to="/write-offs/create">Create Write Off</Button>
        </FlexItem>
      </FlexContainer>
      {filtered && !!filtered.length && (
        <Table>
          <TableHead>
            <TableRow>
              <TableHeadItem>Date</TableHeadItem>
              <TableHeadItem>Notes</TableHeadItem>
              <TableHeadItem style={{ textAlign: 'right' }}>
                Total Cost
              </TableHeadItem>
            </TableRow>
          </TableHead>
          <TableBody>
            {filtered.map((writeOff) => (
              <WriteOffsRow key={writeOff._id} writeOff={writeOff} />
            ))}
          </TableBody>
        </Table>
      )}
      {!filtered && <TableSkeleton />}
      {filtered && !filtered.length && (
        <P>
          No write offs found. Try adjusting your date filters or create your
          first write off.
        </P>
      )}
    </>
  );
};

export default Customers;
