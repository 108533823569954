import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

const StyledTextarea = styled.textarea`
  display: block;
  resize: vertical;
  width: var(--input-width);
  border-radius: var(--input-border-radius);
  border: var(--input-border);
  ${({ hasError }) => hasError && 'border: var(--input-border--error);'}
  padding: var(--input-padding);
  background-color: var(--input-background-color);
  box-shadow: var(--input-box-shadow);
  transition: var(--input-transitions);
  line-height: var(--input-line-height);
  font-size: var(--input-font-size);

  &:focus {
    background-color: var(--input-background-color-focus);
    border: var(--input-border-focus);
    ${({ hasError }) => hasError && 'border: var(--input-border-focus--error);'}
    box-shadow: var(--input-box-shadow-focus);
    ${({ hasError }) =>
      hasError && 'box-shadow: var(--input-box-shadow-focus--error);'}
  }

  &:disabled {
    background-color: var(--input-background-color--disabled);
    color: var(--input-color--disabled);
    border: var(--input-border--disabled);
  }
`;

const Textarea = ({ children, ...props }) => {
  return <StyledTextarea {...props}>{children}</StyledTextarea>;
};

Textarea.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

Textarea.defaultProps = {
  children: null,
};

export default Textarea;
