import styled from 'styled-components/macro';
import {
  Table,
  TableBody,
  TableHead,
  TableHeadItem,
  TableItem,
  TableRow,
} from '../elements/Elements';

const NUMBER_OF_ROWS = 10;

const MockItem = styled.div`
  background-color: var(--gray--background);
  color: transparent;
  border-radius: var(--border-radius);
`;

const TableSkeleton = () => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableHeadItem>
            <MockItem>Something fake</MockItem>
          </TableHeadItem>
        </TableRow>
      </TableHead>
      <TableBody>
        {[...Array(NUMBER_OF_ROWS)].map((_, key) => (
          // eslint-disable-next-line react/no-array-index-key
          <TableRow key={key}>
            <TableItem>
              <MockItem>Something fake</MockItem>
            </TableItem>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default TableSkeleton;
