import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

const StyledFormGroup = styled.div.attrs({ className: 'form-group' })`
  display: flex;
  margin: 2rem 0;

  &:first-child {
    margin-top: 0;
  }

  & > div + div {
    margin-left: 1rem;
  }

  @media (max-width: 600px) {
    flex-direction: column;

    & > div + div {
      margin-left: 0;
      margin-top: 1.5rem;
    }
  }
`;

const FormGroup = ({ children, ...props }) => {
  return <StyledFormGroup {...props}>{children}</StyledFormGroup>;
};

FormGroup.propTypes = {
  children: PropTypes.element.isRequired,
};

export default FormGroup;
