import { NavLink } from 'react-router-dom';
import { FiChevronRight } from 'react-icons/fi';
import SaleForm from './SaleForm';
import { H1 } from './elements/Elements';

const CreateSale = () => {
  return (
    <div style={{ maxWidth: 'var(--narrow-max-width)' }}>
      <H1>
        <NavLink to="/sales">Sales</NavLink> <FiChevronRight /> Create Sale
      </H1>
      <SaleForm />
    </div>
  );
};

export default CreateSale;
