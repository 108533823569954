import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import TableItem from './TableItem';

const StyledTableHeadItem = styled(TableItem)`
  border-top: none;
  font-size: 0.7rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.75px;
  color: var(--gray);
  padding: 0.75rem 1.25rem;
  white-space: nowrap;
`;

const TableHeadItem = ({ children, ...props }) => {
  return <StyledTableHeadItem {...props}>{children}</StyledTableHeadItem>;
};

TableHeadItem.propTypes = {
  children: PropTypes.node,
};

TableHeadItem.defaultProps = {
  children: null,
};

export default TableHeadItem;
