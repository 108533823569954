import styled from 'styled-components/macro';
import Metrics from '../Metrics';
import Metric from '../Metric';

const NUMBER_OF_METRICS = 11;

const MockItem = styled.div`
  background-color: var(--gray--border);
  color: transparent;
  border-radius: var(--border-radius);
`;

const TableSkeleton = () => {
  return (
    <Metrics>
      {[...Array(NUMBER_OF_METRICS)].map((_, key) => (
        <Metric
          // eslint-disable-next-line react/no-array-index-key
          key={key}
          label={<MockItem>Fake</MockItem>}
          value={<MockItem>Fake</MockItem>}
        />
      ))}
    </Metrics>
  );
};

export default TableSkeleton;
