import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { TableRow, TableItem, Button } from './elements/Elements';
import { humanReadableDollars } from '../utils/helpers';

const WriteOffFormProduct = ({ product }) => {
  const { _id: id, name, cost, salesTax, price } = product;
  const { values, setFieldValue } = useFormikContext();

  const removeProduct = () => {
    const index = values.products
      .map((productToRemove) => productToRemove._id)
      .indexOf(id);

    const { products } = values;

    products.splice(index, 1);

    setFieldValue('products', products);
  };

  return (
    <TableRow key={id}>
      <TableItem>{name}</TableItem>
      <TableItem style={{ textAlign: 'right' }}>
        {humanReadableDollars((cost + salesTax) / 100)}
      </TableItem>
      <TableItem style={{ textAlign: 'right' }}>
        {humanReadableDollars(price / 100)}
      </TableItem>
      <TableItem>
        <Button
          style={{ marginLeft: 'auto' }}
          tiny
          type="button"
          onClick={removeProduct}
        >
          Remove
        </Button>
      </TableItem>
    </TableRow>
  );
};

WriteOffFormProduct.propTypes = {
  product: PropTypes.object.isRequired,
};

export default WriteOffFormProduct;
