import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

const StyledMetrics = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
  grid-auto-rows: 1fr;
  grid-gap: calc(var(--space) / 2);
`;

const Metrics = ({ children }) => {
  return <StyledMetrics>{children}</StyledMetrics>;
};

Metrics.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Metrics;
