import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

const StyledCheckbox = styled.input``;

const Checkbox = ({ children, ...props }) => {
  return (
    <StyledCheckbox type="checkbox" {...props}>
      {children}
    </StyledCheckbox>
  );
};

Checkbox.propTypes = {
  children: PropTypes.element.isRequired,
};

export default Checkbox;
