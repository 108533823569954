import { useState } from 'react';
import styled from 'styled-components/macro';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { Formik, Form, ErrorMessage } from 'formik';
import { FiPlus } from 'react-icons/fi';
import { Link, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import Client from '../utils/network';
import {
  Input,
  FormikField,
  InputGroup,
  FlexItem,
  Button,
  Table,
  TableHead,
  TableHeadItem,
  TableBody,
  TableRow,
  FlexContainer,
  FormError,
  Label,
  Select,
} from './elements/Elements';
import { formatMoney, humanReadableDollars } from '../utils/helpers';
import {
  PAYMENT_METHODS,
  APP_CHECKOUT_METHOD,
  APP_CREDIT_METHOD,
} from '../constants';
import SaleFormProducts from './SaleFormProducts';
import ProductSelectModal from './ProductSelectModal';
import CustomerSelectModal from './CustomerSelectModal';
import SaleFormPaymentMethodInput from './SaleFormPaymentMethodInput';
import SaleFormTotals from './SaleFormTotals';
import ResourceSection from './ResourceSection';

const Customer = styled.div`
  display: flex;
  background-color: var(--gray--background);
  padding: 1rem;
  border-radius: var(--border-radius);
`;

const Name = styled.span`
  display: flex;
  font-weight: 500;
`;

const Email = styled.span`
  display: block;
  font-size: 0.75rem;
  color: var(--gray);
  margin-top: 4px;
`;

const Credit = styled.span`
  display: block;
  position: absolute;
  top: 50%;
  right: 1px;
  transform: translateY(-50%);
  border-radius: var(--border-radius);
  font-weight: 300;
  font-size: 0.9rem;
  color: var(--gray);
  padding: 0.75rem 1rem;
  pointer-events: none;
`;

const SaleForm = ({ data }) => {
  const [showProductSelect, setShowProductSelect] = useState(false);
  const [showCustomerSelect, setShowCustomerSelect] = useState(false);
  const { push } = useHistory();

  return (
    <ResourceSection>
      <Formik
        initialValues={{
          customer: data?.customer || null,
          products: data?.products || [],
          paymentMethod: data?.paymentMethod || APP_CREDIT_METHOD,
          shippingPrice: data?.shippingPrice
            ? humanReadableDollars(data.shippingPrice / 100)
            : '$0.00',
          appliedCredit: data?.appliedCredit
            ? humanReadableDollars(data.appliedCredit / 100)
            : '$0.00',
          paid: data?.paid || false,
        }}
        onSubmit={async (values) => {
          try {
            const { method, path, successPastVerb } = {
              method: data ? 'put' : 'post',
              path: data ? `/updateSale?id=${data._id}` : '/createSale',
              successPastVerb: data ? 'updated' : 'created',
            };

            await Client[method](path, {
              ...values,
              customer: values.customer._id,
              products: values.products.map(({ _id: id }) => id),
            });

            toast.success(`Sale successfully ${successPastVerb}.`);
            push('/sales');
          } catch (error) {
            toast.error(error);
          }
        }}
        validationSchema={() =>
          Yup.object().shape({
            customer: Yup.object()
              .nullable()
              .required('Please select a customer.'),
            products: Yup.array()
              .of(Yup.object())
              .required()
              .min(1, 'Please add at least one product.'),
            paymentMethod: Yup.string()
              .oneOf(PAYMENT_METHODS, 'Please choose a payment method.')
              .required('Please choose a payment method.'),
            shippingPrice: Yup.string().nullable(),
            appliedCredit: Yup.string().nullable(),
            paid: Yup.boolean().required('Please choose a paid status.'),
          })
        }
      >
        {(formProps) => {
          return (
            <Form>
              <ProductSelectModal
                isOpen={showProductSelect}
                close={() => setShowProductSelect(false)}
              />
              <CustomerSelectModal
                isOpen={showCustomerSelect}
                close={() => setShowCustomerSelect(false)}
              />
              <Label>Customer</Label>
              <Customer>
                <div style={{ flex: 1, margin: 'auto 0' }}>
                  {formProps.values.customer ? (
                    <Link
                      target="_blank"
                      to={`/customers/${formProps.values.customer._id}`}
                    >
                      <Name>{formProps.values.customer.name}</Name>
                    </Link>
                  ) : (
                    <Name>Select a Customer</Name>
                  )}
                  {formProps.values.customer?.email && (
                    <Email>{formProps.values.customer.email}</Email>
                  )}
                </div>
                <Button
                  style={{
                    margin: 'auto 0',
                  }}
                  small
                  type="button"
                  onClick={() => setShowCustomerSelect(true)}
                  disabled={!!data}
                >
                  {formProps.values.customer ? 'Replace' : 'Choose'}
                </Button>
                <Button
                  style={{
                    margin: 'auto 0 auto 10px',
                  }}
                  small
                  type="button"
                  to="/customers/create?next=/sales/create"
                  disabled={!!data}
                >
                  Create Customer
                </Button>
              </Customer>
              <ErrorMessage name="customer" component={FormError} />
              <Table style={{ marginTop: '2rem' }}>
                <TableHead>
                  <TableRow>
                    <TableHeadItem>Name</TableHeadItem>
                    <TableHeadItem
                      style={{ textAlign: 'right', width: '150px' }}
                    >
                      Cost
                    </TableHeadItem>
                    <TableHeadItem
                      style={{ textAlign: 'right', width: '150px' }}
                    >
                      Price
                    </TableHeadItem>
                    <TableHeadItem />
                  </TableRow>
                </TableHead>
                <TableBody>
                  <SaleFormProducts locked={data?.paid} />
                </TableBody>
              </Table>
              <Button
                style={{
                  marginTop: '1rem',
                }}
                disabled={!!data?.paid}
                small
                type="button"
                onClick={() => setShowProductSelect(true)}
              >
                <FiPlus />
                Add Product
              </Button>
              <ErrorMessage name="products" component={FormError} />
              <InputGroup>
                <FlexItem>
                  <FormikField
                    {...formProps}
                    name="shippingPrice"
                    as={Input}
                    placeholder="$0.00"
                    label="Shipping Price"
                    type="text"
                    onBlur={() =>
                      formProps.setFieldValue(
                        'shippingPrice',
                        formatMoney(formProps.values.shippingPrice),
                        false,
                      )
                    }
                    onClick={() => {
                      document.execCommand('selectall', null, false);
                    }}
                    disabled={!!data?.paid}
                  />
                </FlexItem>
                <FlexItem>
                  <FormikField
                    {...formProps}
                    name="appliedCredit"
                    as={Input}
                    placeholder="$0.00"
                    label="Applied Credit"
                    type="text"
                    onBlur={() =>
                      formProps.setFieldValue(
                        'appliedCredit',
                        formatMoney(formProps.values.appliedCredit),
                        false,
                      )
                    }
                    onClick={() => {
                      document.execCommand('selectall', null, false);
                    }}
                    disabled={!!data?.paid}
                  >
                    <Credit>
                      Customer Credit:{' '}
                      {humanReadableDollars(
                        (formProps.values.customer?.credit || 0) / 100,
                      )}
                    </Credit>
                  </FormikField>
                </FlexItem>
              </InputGroup>
              <InputGroup>
                <FlexItem>
                  <SaleFormPaymentMethodInput data={data} />
                </FlexItem>
                <FlexItem>
                  <FormikField
                    {...formProps}
                    as={Select}
                    name="paid"
                    label="Payment Status"
                    disabled={
                      !!data?.paid ||
                      formProps.values.paymentMethod === APP_CHECKOUT_METHOD ||
                      formProps.values.paymentMethod === APP_CREDIT_METHOD
                    }
                  >
                    <option disabled value="Select One">
                      Select One
                    </option>
                    {[
                      { label: 'Unpaid', value: false },
                      { label: 'Paid', value: true },
                    ].map(({ label, value }) => (
                      <option key={value} value={value}>
                        {label}
                      </option>
                    ))}
                  </FormikField>
                </FlexItem>
              </InputGroup>
              <FlexContainer>
                {!data?.paid && (
                  <FlexItem marginAuto>
                    <Button loading={formProps.isSubmitting} type="submit">
                      Save Sale
                    </Button>
                  </FlexItem>
                )}
                <SaleFormTotals paid={data?.paid} />
              </FlexContainer>
            </Form>
          );
        }}
      </Formik>
    </ResourceSection>
  );
};

SaleForm.propTypes = {
  data: PropTypes.object,
};

SaleForm.defaultProps = {
  data: null,
};

export default SaleForm;
