import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

const StyledTableWrapper = styled.div`
  overflow-x: scroll;
`;

const StyledTable = styled.table`
  min-width: 100%;
  border-collapse: collapse;
`;

const Table = ({ children, ...props }) => {
  return (
    <StyledTableWrapper {...props}>
      <StyledTable>{children}</StyledTable>
    </StyledTableWrapper>
  );
};

Table.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Table;
