import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import moment from 'moment';
import { FaChevronDown } from 'react-icons/fa';
import { motion } from 'framer-motion';
import DateFilterRange from './DateFilterRange';
import useCloseSomething from '../hooks/useCloseSomething';

const DATE_RANGE_OPTIONS = [
  {
    label: 'All Time',
    details: 'Through Today',
    start: new Date(2000, 1, 1),
    end: new Date(3000, 1, 1),
  },
  {
    label: 'This Month',
    details: `${moment().clone().startOf('month').format('MMM Do')} - ${
      moment().clone().endOf('month') > moment()
        ? 'Today'
        : moment().clone().endOf('month').format('MMM Do')
    }`,
    start: new Date(moment().clone().startOf('month')),
    end: new Date(moment().clone().endOf('month')),
  },
  {
    label: 'Last Month',
    details: `${moment()
      .clone()
      .subtract(1, 'month')
      .startOf('month')
      .format('MMM Do')} - ${moment()
      .clone()
      .subtract(1, 'month')
      .endOf('month')
      .format('MMM Do')}`,
    start: new Date(moment().clone().subtract(1, 'month').startOf('month')),
    end: new Date(moment().clone().subtract(1, 'month').endOf('month')),
  },
  {
    label: 'This Year',
    details: `${moment().clone().startOf('year').format('MMM Do, YYYY')} - ${
      moment().clone().endOf('year') > moment()
        ? 'Today'
        : moment().clone().endOf('year').format('MMM Do, YYYY')
    }`,
    start: new Date(moment().clone().startOf('year')),
    end: new Date(moment().clone().endOf('year')),
  },
  {
    label: 'Last Year',
    details: `${moment()
      .clone()
      .subtract(1, 'year')
      .startOf('year')
      .format('MMM Do, YYYY')} - ${moment()
      .clone()
      .subtract(1, 'year')
      .endOf('year')
      .format('MMM Do, YYYY')}`,
    start: new Date(moment().clone().subtract(1, 'year').startOf('year')),
    end: new Date(moment().clone().subtract(1, 'year').endOf('year')),
  },
  {
    label: 'Custom',
    details: 'Provided Date Range',
    start: new Date(moment().clone().startOf('week')),
    end: new Date(moment().clone().endOf('week')),
    showDateInput: true,
  },
];

const ActiveOptionWrapper = styled.div`
  display: flex;
  white-space: nowrap;
  border: var(--input-border);
  border-radius: var(--border-radius);
`;

const OptionsTrigger = styled.button`
  position: relative;
  background-color: var(--gray--vague);
  border: none;
  border-radius: 0 var(--border-radius) var(--border-radius) 0;
  padding: 0 18px;
  margin-left: 0;
  border-left: 1px solid var(--gray--border);

  &:active,
  &.active {
    background-color: var(--gray--background);
  }

  svg {
    position: absolute;
    top: 52%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
    font-size: 0.65rem;
    fill: var(--gray);
  }
`;

const ActiveOptionInner = styled.div`
  border-radius: var(--border-radius) 0 0 var(--border-radius);
  padding: 10px 18px;
  text-align: left;
`;

const Options = styled(motion.div)`
  position: absolute;
  background-color: var(--white);
  top: 3px;
  ${({ anchorDirection }) => `${anchorDirection}: 0;`}
  border: 1px solid var(--gray--border);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  overflow: hidden;
  z-index: 1;
`;

const OptionButton = styled.button`
  display: block;
  background-color: transparent;
  border: none;
  width: 100%;
  padding: 12px 18px;
  text-align: left;
  white-space: nowrap;

  &:hover {
    background-color: var(--gray--vague);
  }

  &:active {
    background-color: var(--gray--background);
  }
`;

const OptionLabel = styled.span`
  display: block;
  font-weight: 500;
`;

const OptionDetails = styled.span`
  display: block;
  color: var(--gray);
  font-size: 0.9rem;
  margin-top: 3px;
`;

const DateFilter = ({ handleChange, storageKey, anchorDirection }) => {
  const prev = JSON.parse(localStorage.getItem(storageKey));
  const [showOptions, setShowOptions] = useState(false);
  const [activeOption, setActiveOption] = useState(
    (prev &&
      DATE_RANGE_OPTIONS.find(
        ({ start, end, showDateInput }) =>
          showDateInput ||
          (start.getTime() === new Date(prev.start).getTime() &&
            end.getTime() === new Date(prev.end).getTime()),
      )) ||
      DATE_RANGE_OPTIONS[1],
  );

  useCloseSomething(showOptions, setShowOptions);

  const updateDate = (value) => {
    localStorage.setItem(storageKey, JSON.stringify(value));
    handleChange(value);
  };

  useEffect(() => {
    updateDate(activeOption);
  }, []);

  const { label, start, end, showDateInput } = activeOption;

  return (
    <div style={{ display: 'inline-block' }}>
      <ActiveOptionWrapper>
        {showDateInput ? (
          <div>
            <DateFilterRange
              handleChange={updateDate}
              initialStart={start}
              initialEnd={end}
            />
          </div>
        ) : (
          <ActiveOptionInner>
            <OptionLabel>{label}</OptionLabel>
          </ActiveOptionInner>
        )}
        <OptionsTrigger
          type="button"
          className={showOptions ? 'active' : ''}
          onClick={() => setShowOptions(!showOptions)}
        >
          <FaChevronDown />
        </OptionsTrigger>
      </ActiveOptionWrapper>
      <div style={{ position: 'relative' }}>
        {showOptions && (
          <Options
            initial="hidden"
            animate="visible"
            variants={{
              hidden: { opacity: 0, y: -10, pointerEvents: 'none' },
              visible: { opacity: 1, y: 0, pointerEvents: 'all' },
            }}
            transition={{
              type: 'spring',
              damping: 30,
              stiffness: 600,
            }}
            anchorDirection={anchorDirection}
          >
            {DATE_RANGE_OPTIONS.map((range) => (
              <OptionButton
                key={range.label}
                active={activeOption.label === range.label}
                onClick={(e) => {
                  e.stopPropagation();
                  updateDate(range);
                  setActiveOption(range);
                  setShowOptions(false);
                }}
              >
                <OptionLabel>{range.label}</OptionLabel>
                <OptionDetails>{range.details}</OptionDetails>
              </OptionButton>
            ))}
          </Options>
        )}
      </div>
    </div>
  );
};

DateFilter.propTypes = {
  storageKey: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  anchorDirection: PropTypes.oneOf(['left', 'right']),
};

DateFilter.defaultProps = {
  anchorDirection: 'right',
};

export default DateFilter;
