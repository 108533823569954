import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

const StyledLabel = styled.label`
  display: block;
  font-size: 0.7rem;
  font-weight: 600;
  text-transform: uppercase;
  padding-bottom: 4px;
  letter-spacing: 0.75px;
  color: var(--gray);
`;

const Label = ({ children, ...props }) => {
  return <StyledLabel {...props}>{children}</StyledLabel>;
};

Label.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string])
    .isRequired,
};

export default Label;
