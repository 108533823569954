import { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';
import { throttle } from 'lodash';
import { humanReadableDollars } from '../utils/helpers';
import Client from '../utils/network';
import {
  Button,
  FlexContainer,
  FlexItem,
  H1,
  Input,
  Table,
  TableBody,
  TableHead,
  TableHeadItem,
  TableItem,
  TableRow,
} from './elements/Elements';
import TableSkeleton from './skeletons/TableSkeleton';
import ContextualFilter from './ContextualFilter';

const MAX_RESULTS = 50;
const SEARCH_THROTTLE_WAIT = 1000;

const Products = () => {
  const [products, setProducts] = useState();
  const [contextualFilter, setContextualFilter] = useState();

  const search = async (searchTerm = '') => {
    try {
      if (!contextualFilter || [1, 2].includes(searchTerm.length)) return;

      const filter = contextualFilter;

      setProducts(
        await Client.get(
          `/getProducts?search=${searchTerm}&limit=${MAX_RESULTS}&filter=${filter}`,
        ),
      );
    } catch (error) {
      toast.error(error);
    }
  };

  const throttledSearch = useCallback(
    throttle((searchTerm) => {
      search(searchTerm);
    }, SEARCH_THROTTLE_WAIT),
    [],
  );

  useEffect(() => {
    search('');
  }, [contextualFilter]);

  return (
    <>
      <FlexContainer style={{ marginBottom: '2rem' }}>
        <FlexItem>
          <H1>Products</H1>
        </FlexItem>
        <FlexItem marginAuto alignRight>
          <Button to="/products/create">Create Product</Button>
        </FlexItem>
      </FlexContainer>
      <FlexContainer style={{ marginBottom: '2rem' }}>
        <FlexItem marginAuto style={{ maxWidth: '400px' }}>
          <Input
            style={{ maxWidth: '400px' }}
            autoFocus
            name="search"
            type="text"
            placeholder="Type to search"
            onChange={({ target: { value } }) => throttledSearch(value)}
          />
        </FlexItem>
      </FlexContainer>
      <div style={{ marginBottom: '2rem' }}>
        <ContextualFilter
          storageKey="_trouve_contextual_filter_products"
          options={[
            { label: 'All Products', value: 'all' },
            { label: 'Sold', value: 'sold' },
            { label: 'Unsold', value: 'unsold' },
            { label: 'Written Off', value: 'written-off' },
          ]}
          handleChange={setContextualFilter}
        />
      </div>
      {products ? (
        <Table>
          <TableHead>
            <TableRow>
              <TableHeadItem>Name</TableHeadItem>
              <TableHeadItem>Notes</TableHeadItem>
              <TableHeadItem style={{ textAlign: 'right' }}>Cost</TableHeadItem>
              <TableHeadItem style={{ textAlign: 'right' }}>
                Price
              </TableHeadItem>
              <TableHeadItem style={{ textAlign: 'right' }}>
                Profit
              </TableHeadItem>
              <TableHeadItem />
            </TableRow>
          </TableHead>
          <TableBody>
            {products.map(
              ({
                _id: id,
                name,
                notes,
                cost,
                salesTax,
                price,
                sale,
                writeOff,
              }) => (
                <TableRow key={id}>
                  <TableItem>{name}</TableItem>
                  <TableItem>{notes || ''}</TableItem>
                  <TableItem style={{ textAlign: 'right' }}>
                    {humanReadableDollars((cost + salesTax) / 100)}
                  </TableItem>
                  <TableItem style={{ textAlign: 'right' }}>
                    {humanReadableDollars(price / 100)}
                  </TableItem>
                  <TableItem
                    style={{
                      textAlign: 'right',
                      color: price - cost - salesTax < 0 && 'var(--error)',
                    }}
                  >
                    {(sale || writeOff) &&
                      humanReadableDollars((price - cost - salesTax) / 100)}
                  </TableItem>
                  <TableItem style={{ textAlign: 'right' }}>
                    <Button tiny to={`/products/${id}`}>
                      {sale || writeOff ? 'View' : 'Edit'}
                    </Button>
                  </TableItem>
                </TableRow>
              ),
            )}
          </TableBody>
        </Table>
      ) : (
        <TableSkeleton />
      )}
    </>
  );
};

export default Products;
