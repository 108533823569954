import { useEffect, useState } from 'react';
import { NavLink, useParams, useHistory, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FiChevronRight } from 'react-icons/fi';
import Client from '../utils/network';
import {
  Button,
  P,
  H1,
  LoadingIndicator,
  Label,
  FlexItem,
  FlexContainer,
} from './elements/Elements';
import SaleForm from './SaleForm';
import { APP_CHECKOUT_METHOD } from '../constants';
import ResourceSection from './ResourceSection';

const Sale = () => {
  const [data, setData] = useState();
  const [deleting, setDeleting] = useState();
  const { id } = useParams();
  const { push } = useHistory();

  useEffect(() => {
    (async () => {
      try {
        setData(await Client.get(`/getSale?id=${id}`));
      } catch (error) {
        toast.error(error);
      }
    })();
  }, [id]);

  const deleteRecord = async () => {
    try {
      setDeleting(true);

      const paidViaTrouveCheckout = data?.paid && data?.stripePaymentIntent;

      if (
        !window.confirm(
          `Please confirm to delete this sale${
            paidViaTrouveCheckout && ' and automatically process a fully refund'
          }.`,
        )
      )
        return;

      await Client.delete(`deleteSale?id=${id}`, { data: {} });

      toast.success(
        `Sale successfully deleted${
          paidViaTrouveCheckout ? ' and refund has been processed.' : ''
        }.`,
      );
      setDeleting(false);
      push('/sales');
    } catch (error) {
      setDeleting(false);
      toast.error(error);
    }
  };

  if (!data) return <LoadingIndicator />;

  return (
    <div style={{ maxWidth: 'var(--narrow-max-width)' }}>
      <H1>
        <NavLink to="/sales">Sales</NavLink> <FiChevronRight /> Sale Details
      </H1>
      {data.paymentMethod === APP_CHECKOUT_METHOD && !data?.paid && (
        <ResourceSection>
          <FlexContainer>
            <FlexItem>
              <Label>Checkout URL</Label>
              <P style={{ marginTop: '0' }}>
                <Link
                  id="checkout-url"
                  to={`/checkout/${data.isi}`}
                >{`${window.location.origin}/checkout/${data.isi}`}</Link>
              </P>
            </FlexItem>
            <FlexItem marginAuto alignRight>
              <Button
                small
                onClick={() => {
                  const range = document.createRange();
                  range.selectNode(document.getElementById('checkout-url'));
                  window.getSelection().removeAllRanges();
                  window.getSelection().addRange(range);
                  document.execCommand('copy');
                  toast.success('Checkout URL copied.');
                }}
              >
                Copy URL
              </Button>
            </FlexItem>
          </FlexContainer>
        </ResourceSection>
      )}
      <SaleForm data={data} />
      <ResourceSection>
        <P style={{ marginBottom: '1rem', fontSize: '.9rem' }}>
          Deleting this sale will remove the sold status from all associated
          products and remove the sale from being considered in reporting.
        </P>
        <Button
          style={{ backgroundColor: 'var(--error)' }}
          onClick={deleteRecord}
          small
          loading={deleting}
        >
          {`Delete Sale${
            data?.paid && data?.stripePaymentIntent ? ' & Process Refund' : ''
          }`}
        </Button>
      </ResourceSection>
    </div>
  );
};

export default Sale;
