import { useFormikContext } from 'formik';
import WriteOffFormProduct from './WriteOffFormProduct';
import P from './elements/P';
import TableRow from './elements/TableRow';
import { TableItem } from './elements/Elements';

const WriteOffFormProducts = () => {
  const {
    values: { products },
  } = useFormikContext();

  return products.length ? (
    products.map((product) => (
      <WriteOffFormProduct key={product._id} product={product} />
    ))
  ) : (
    <TableRow>
      <TableItem style={{ textAlign: 'center' }} colSpan="5">
        <P style={{ fontWeight: 500, fontSize: '0.9rem' }}>
          No products selected for this write off.
        </P>
      </TableItem>
    </TableRow>
  );
};

export default WriteOffFormProducts;
