import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const StyledLink = styled(Link)`
  display: inline-block;
  margin: auto 0;
  line-height: 1;
`;

const StyledButton = styled.button`
  display: flex;
  position: relative;
  background-color: var(--blue);
  border: none;
  padding: 12px 18px;
  ${({ small }) => small && 'padding: .6rem .6rem .5rem .6rem;'}
  ${({ tiny }) => tiny && 'padding: .4rem .4rem .3rem .4rem;'}
  border-radius: var(--border-radius);
  color: var(--white);
  font-weight: 500;
  ${({ small }) => small && 'font-size: .9rem;'}
  ${({ tiny }) => tiny && 'font-size: .75rem;'}
  line-height: 1;
  white-space: nowrap;

  &:active {
    opacity: 0.85;
  }

  &:disabled {
    background-color: var(--gray);
    pointer-events: none;
  }

  &.loading {
    color: transparent;
    background-color: var(--gray--background) !important;
    pointer-events: none;
  }

  svg:first-child {
    margin: auto 0 auto 5px;
    stroke-width: 3px;
  }

  svg:first-child {
    margin: auto 5px auto 0;
    stroke-width: 3px;
  }
`;

const LoadingIndicator = styled.div`
  position: absolute;
  top: 54%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%) scale(0.6, 0.6);
`;

const Button = ({
  children,
  type,
  to,
  loading,
  disabled,
  tiny,
  small,
  ...props
}) => {
  return to ? (
    <StyledLink
      tabIndex="-1"
      style={disabled ? { pointerEvents: 'none' } : {}}
      to={to}
    >
      <StyledButton
        type="button"
        disabled={disabled}
        tiny={tiny}
        small={small}
        {...props}
      >
        {children}
      </StyledButton>
    </StyledLink>
  ) : (
    <StyledButton
      type={type}
      disabled={disabled}
      tiny={tiny}
      small={small}
      {...props}
      className={loading && 'loading'}
    >
      {children}
      {loading && (
        <LoadingIndicator className="loader-inner ball-clip-rotate">
          <div
            style={{
              border: '2px solid transparent',
              borderBottomColor: 'var(--gray)',
            }}
          />
        </LoadingIndicator>
      )}
    </StyledButton>
  );
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.string,
  to: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  tiny: PropTypes.bool,
  small: PropTypes.bool,
};

Button.defaultProps = {
  type: 'button',
  to: null,
  loading: false,
  disabled: false,
  tiny: false,
  small: false,
};

export default Button;
