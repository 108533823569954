import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

const Wrapper = styled.div`
  margin: var(--space) 0;
  padding-bottom: var(--space);
  border-bottom: 1px solid var(--gray--border);

  &:last-child {
    margin-bottom: none;
    padding-bottom: none;
    border-bottom: none;
  }
`;

const ResourceSection = ({ children }) => {
  return <Wrapper>{children}</Wrapper>;
};

ResourceSection.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ResourceSection;
