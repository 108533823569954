import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

const StyledMetric = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--gray--background);
  border-radius: var(--border-radius);
  padding: calc(var(--space) / 2);
`;

const Label = styled.div``;

const Value = styled.div`
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 8px;
`;

const Metrics = ({ label, value, ...props }) => {
  return (
    <StyledMetric {...props}>
      <Value>{value}</Value>
      <Label>{label}</Label>
    </StyledMetric>
  );
};

Metrics.propTypes = {
  label: PropTypes.node.isRequired,
  value: PropTypes.node.isRequired,
};

export default Metrics;
