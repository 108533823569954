import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Button, FlexContainer, FlexItem, H1 } from './elements/Elements';
import Client from '../utils/network';
import { humanReadableDollars } from '../utils/helpers';
import MetricsSkeleton from './skeletons/MetricsSkeleton';
import Metrics from './Metrics';
import Metric from './Metric';
import DateFilter from './DateFilter';

const Dashboard = () => {
  const [metrics, setMetrics] = useState();
  const [dateRange, setDateRange] = useState();

  useEffect(() => {
    (async () => {
      try {
        if (!dateRange) return;

        const { start, end } = dateRange;

        const rangeMetrics = await Client.get(
          `/getMetrics?start=${start}&end=${end}`,
        );

        setMetrics(
          rangeMetrics.map(({ label, value, type }) => ({
            label,
            type,
            value: `${value}`,
          })),
        );
      } catch (error) {
        toast.error(error);
      }
    })();
  }, [dateRange]);

  return (
    <>
      <FlexContainer style={{ marginBottom: '2rem' }}>
        <FlexItem>
          <H1>Dashboard</H1>
        </FlexItem>
        <FlexItem marginAuto>
          <FlexContainer style={{ justifyContent: 'flex-end' }}>
            <Button to="/sales/create">Create Sale</Button>
            <Button style={{ marginLeft: '10px' }} to="/products/create">
              Create Product
            </Button>
          </FlexContainer>
        </FlexItem>
      </FlexContainer>
      <div style={{ marginBottom: '2rem' }}>
        <DateFilter
          storageKey="_trouve_date_filter_metrics"
          handleChange={({ start, end }) => {
            if (!start || !end) return;
            setDateRange({ start, end });
          }}
          anchorDirection="left"
        />
      </div>
      {metrics ? (
        <Metrics>
          {metrics.map(({ label, value, type }) => (
            <Metric
              key={label}
              value={
                (type === 'COUNT' && value) ||
                (type === 'CURRENCY' && (
                  <span style={{ color: value < 0 && 'var(--error)' }}>
                    {humanReadableDollars(value / 100)}
                  </span>
                ))
              }
              label={label}
            />
          ))}
        </Metrics>
      ) : (
        <MetricsSkeleton />
      )}
    </>
  );
};

export default Dashboard;
